import { Loadout } from "../../types/types";

const origin: Loadout[] = [
  {
    name: "100i",
    notes: [],
    avionics: {
      powerplants: [
        { key: 1, value: "PowerBolt" },
      ],
      coolers: [
        { key: 1, value: "ArcticStorm" },
        { key: 2, value: "ArcticStorm" },
      ],
      shields: [
        { key: 1, value: "WEB" },
      ],
      quantumdrive: "Expedition"
    },
    weaponry: {
      turrets: [],
      hardpoints: [
        {
          key: 1,
          weapon: "M4A",
          name: "Wing",
          type: "Fixed",
          size: 2,
        },
        {
          key: 2,
          weapon: "M4A",
          name: "Wing",
          type: "Fixed",
          size: 2,
        }
      ]
    },
    missiles: [
      {
        key: 1,
        rack: "SNT-222 Missile Rack",
        missiles: [
          { key: 1, value: "StrikeForce II" },
          { key: 2, value: "StrikeForce II" },
        ]
      },
    ],
    alternatives: []
  },
  {
    name: "125a",
    notes: [],
    avionics: {
      powerplants: [
        { key: 1, value: "Regulus" },
      ],
      coolers: [
        { key: 1, value: "Bracer" },
        { key: 2, value: "Bracer" },
      ],
      shields: [
        { key: 1, value: "AllStop" },
      ],
      quantumdrive: "Expedition"
    },
    weaponry: {
      turrets: [],
      hardpoints: [
        {
          key: 1,
          weapon: "M4A",
          name: "Wing",
          type: "Fixed",
          size: 2,
        },
        {
          key: 2,
          weapon: "M4A",
          name: "Wing",
          type: "Fixed",
          size: 2,
        }
      ]
    },
    missiles: [
      {
        key: 1,
        rack: "SNT-222 Missile Rack",
        missiles: [
          { key: 1, value: "StrikeForce II" },
          { key: 2, value: "StrikeForce II" },
        ]
      },
      {
        key: 2,
        rack: "SNT-242 Missile Rack",
        missiles: [
          { key: 1, value: "StrikeForce II" },
          { key: 2, value: "StrikeForce II" },
          { key: 3, value: "StrikeForce II" },
          { key: 4, value: "StrikeForce II" },
        ]
      },
    ],
    alternatives: []
  },
  {
    name: "135c",
    notes: [],
    avionics: {
      powerplants: [
        { key: 1, value: "Fortitude" },
      ],
      coolers: [
        { key: 1, value: "Thermax" },
        { key: 2, value: "Thermax" },
      ],
      shields: [
        { key: 1, value: "Bulwark" },
      ],
      quantumdrive: "Expedition"
    },
    weaponry: {
      turrets: [],
      hardpoints: [
        {
          key: 1,
          weapon: "M4A",
          name: "Wing",
          type: "Fixed",
          size: 2,
        },
        {
          key: 2,
          weapon: "M4A",
          name: "Wing",
          type: "Fixed",
          size: 2,
        }
      ]
    },
    missiles: [
      {
        key: 1,
        rack: "SNT-222 Missile Rack",
        missiles: [
          { key: 1, value: "StrikeForce II" },
          { key: 2, value: "StrikeForce II" },
        ]
      },
    ],
    alternatives: []
  },
  {
    name: "300i",
    notes: [],
    avionics: {
      powerplants: [
        { key: 1, value: "PowerBolt" },
      ],
      coolers: [
        { key: 1, value: "ArcticStorm" },
        { key: 2, value: "ArcticStorm" },
      ],
      shields: [
        { key: 1, value: "WEB" },
        { key: 2, value: "WEB" },
      ],
      quantumdrive: "Expedition"
    },
    weaponry: {
      turrets: [],
      hardpoints: [
        {
          key: 1,
          weapon: 'SW16BR3 "Shredder"',
          name: "Wing",
          type: "Fixed",
          size: 3,
        },
        {
          key: 2,
          weapon: 'SW16BR3 "Shredder"',
          name: "Wing",
          type: "Fixed",
          size: 3,
        },
        {
          key: 3,
          weapon: "M5A",
          name: "Nose",
          type: "Fixed",
          size: 3,
        }
      ]
    },
    missiles: [
      {
        key: 1,
        rack: "MSD-212 Missile Rack",
        missiles: [
          { key: 1, value: "StrikeForce II" },
          { key: 2, value: "StrikeForce II" },
        ]
      },
      {
        key: 2,
        rack: "MSD-212 Missile Rack",
        missiles: [
          { key: 1, value: "StrikeForce II" },
          { key: 2, value: "StrikeForce II" },
        ]
      },
    ],
    alternatives: []
  },
  {
    name: "315p",
    notes: [],
    avionics: {
      powerplants: [
        { key: 1, value: "SonicLite" },
      ],
      coolers: [
        { key: 1, value: "HeatSafe" },
        { key: 2, value: "HeatSafe" },
      ],
      shields: [
        { key: 1, value: "Shimmer" },
        { key: 2, value: "Shimmer" },
      ],
      quantumdrive: "Goliath"
    },
    weaponry: {
      turrets: [],
      hardpoints: [
        {
          key: 1,
          weapon: 'SW16BR3 "Shredder"',
          name: "Wing",
          type: "Fixed",
          size: 3,
        },
        {
          key: 2,
          weapon: 'SW16BR3 "Shredder"',
          name: "Wing",
          type: "Fixed",
          size: 3,
        },
      ]
    },
    missiles: [
      {
        key: 1,
        rack: "MSD-212 Missile Rack",
        missiles: [
          { key: 1, value: "Tempest II" },
        ]
      },
      {
        key: 2,
        rack: "MSD-212 Missile Rack",
        missiles: [
          { key: 1, value: "Tempest II" },
        ]
      },
    ],
    alternatives: []
  },
  {
    name: "325a",
    notes: [],
    avionics: {
      powerplants: [
        { key: 1, value: "Regulus" },
      ],
      coolers: [
        { key: 1, value: "Bracer" },
        { key: 2, value: "Bracer" },
      ],
      shields: [
        { key: 1, value: "AllStop" },
        { key: 2, value: "AllStop" },
      ],
      quantumdrive: "Beacon"
    },
    weaponry: {
      turrets: [],
      hardpoints: [
        {
          key: 1,
          weapon: 'SW16BR3 "Shredder"',
          name: "Wing",
          type: "Fixed",
          size: 3,
        },
        {
          key: 2,
          weapon: 'SW16BR3 "Shredder"',
          name: "Wing",
          type: "Fixed",
          size: 3,
        },
        {
          key: 3,
          weapon: 'M6A',
          name: "Nose",
          type: "Fixed",
          size: 4,
        },
      ]
    },
    missiles: [
      {
        key: 1,
        rack: "MSD-322 Missile Rack",
        missiles: [
          { key: 1, value: "Dominator II" },
          { key: 2, value: "Dominator II" },
        ]
      },
      {
        key: 2,
        rack: "MSD-322 Missile Rack",
        missiles: [
          { key: 1, value: "Dominator II" },
          { key: 2, value: "Dominator II" },
        ]
      },
      {
        key: 3,
        rack: "SNT-143 Missile Rack",
        missiles: [
          { key: 1, value: "Arrester III" },
          { key: 2, value: "Arrester III" },
          { key: 3, value: "Arrester III" },
          { key: 4, value: "Arrester III" },
        ]
      },
    ],
    alternatives: []
  },
  {
    name: "350r",
    notes: [],
    avionics: {
      powerplants: [
        { key: 1, value: "StarHeart" },
      ],
      coolers: [
        { key: 1, value: "QuikCool" },
        { key: 2, value: "QuikCool" },
      ],
      shields: [
        { key: 1, value: "Targa" },
        { key: 2, value: "Targa" },
      ],
      quantumdrive: "Beacon"
    },
    weaponry: {
      turrets: [],
      hardpoints: [
        {
          key: 1,
          weapon: 'SW16BR3 "Shredder"',
          name: "Wing",
          type: "Fixed",
          size: 3,
        },
        {
          key: 2,
          weapon: 'SW16BR3 "Shredder"',
          name: "Wing",
          type: "Fixed",
          size: 3,
        },
        {
          key: 3,
          weapon: "M5A",
          name: "Nose",
          type: "Fixed",
          size: 3,
        }
      ]
    },
    missiles: [
      {
        key: 1,
        rack: "MSD-212 Missile Rack",
        missiles: [
          { key: 1, value: "StrikeForce II" },
          { key: 2, value: "StrikeForce II" },
        ]
      },
      {
        key: 2,
        rack: "MSD-212 Missile Rack",
        missiles: [
          { key: 1, value: "StrikeForce II" },
          { key: 2, value: "StrikeForce II" },
        ]
      },
    ],
    alternatives: []
  },
  {
    name: "600i",
    notes: [],
    avionics: {
      powerplants: [
        { key: 1, value: "SuperDrive" },
      ],
      coolers: [
        { key: 1, value: "FrostBurn" },
      ],
      shields: [
        { key: 1, value: "Stronghold" },
      ],
      quantumdrive: "Odyssey"
    },
    weaponry: {
      turrets: [
        {
          key: 1,
          name: "Remote 2xS3",
          hardpoints: [
            {
              key: 1,
              name: "Turret",
              type: "Turret",
              size: 3,
              weapon: "DR Model-XJ3"
            },
            {
              key: 2,
              name: "Turret",
              type: "Turret",
              size: 3,
              weapon: "DR Model-XJ3"
            }
          ]
        },
        {
          key: 2,
          name: "Remote 2xS3",
          hardpoints: [
            {
              key: 1,
              name: "Turret",
              type: "Turret",
              size: 3,
              weapon: "DR Model-XJ3"
            },
            {
              key: 2,
              name: "Turret",
              type: "Turret",
              size: 3,
              weapon: "DR Model-XJ3"
            }
          ]
        }
      ],
      hardpoints: [
        {
          key: 1,
          weapon: 'M7A',
          name: "Wing",
          type: "Fixed",
          size: 5,
        },
        {
          key: 2,
          weapon: 'M7A',
          name: "Wing",
          type: "Fixed",
          size: 5,
        },
        {
          key: 3,
          weapon: 'M7A',
          name: "Top",
          type: "Fixed",
          size: 5,
        }
      ]
    },
    missiles: [
      {
        key: 1,
        rack: "SNT-543 Missile Rack",
        missiles: [
          { key: 1, value: "Arrester III" },
          { key: 2, value: "Arrester III" },
          { key: 3, value: "Arrester III" },
          { key: 4, value: "Arrester III" },
        ]
      },
      {
        key: 2,
        rack: "SNT-543 Missile Rack",
        missiles: [
          { key: 1, value: "Arrester III" },
          { key: 2, value: "Arrester III" },
          { key: 3, value: "Arrester III" },
          { key: 4, value: "Arrester III" },
        ]
      },
      {
        key: 3,
        rack: "SNT-543 Missile Rack",
        missiles: [
          { key: 1, value: "Arrester III" },
          { key: 2, value: "Arrester III" },
          { key: 3, value: "Arrester III" },
          { key: 4, value: "Arrester III" },
        ]
      },
      {
        key: 4,
        rack: "SNT-543 Missile Rack",
        missiles: [
          { key: 1, value: "Arrester III" },
          { key: 2, value: "Arrester III" },
          { key: 3, value: "Arrester III" },
          { key: 4, value: "Arrester III" },
        ]
      },
    ],
    alternatives: []
  },
]

export default origin;