import axios, { AxiosInstance } from 'axios';

const saleLocations = '/salelocations'

class ApiClient {
  instance: AxiosInstance;
  url: string;

  constructor() {
    this.url = process.env.REACT_APP_API_URL || '';
    this.instance = axios.create({
      baseURL: process.env.REACT_APP_API_URL,
    })
  }

  async GetSaleLocations(itemName:string){
    let result = await this.instance.get(this.url + saleLocations+`?item=${itemName}`);

    return result.data
  }
}

export default new ApiClient();