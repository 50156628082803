import { AccordionButton, AccordionIcon, AccordionItem, AccordionPanel } from "@chakra-ui/accordion";
import { Box } from "@chakra-ui/layout";
import { LocatedItem } from "../../types/types";
import { SaleItem } from "./SaleItem";

type ItemSaleLocationProps = {
  location: string,
  items: LocatedItem[]
  boughtSetter: (item: string, bought: boolean) => void
}

export function ItemSaleLocation(props: ItemSaleLocationProps) {

  return (
    <AccordionItem>
      <h2>
        <AccordionButton>
          <Box flex="1" textAlign="left">
            {`${props.items.filter(i => !i.bought).length} of remaining items at ${props.location.split('-').reverse().join(' - ')}`}
          </Box>
          <AccordionIcon />
        </AccordionButton>
      </h2>
      <AccordionPanel pb={4}>
        {props.items.map(i => <SaleItem
          item={i.item}
          quantity={i.quantity}
          price={i.price}
          bought={i.bought}
          boughtSetter={props.boughtSetter}
          key={i.item}
        />)}
      </AccordionPanel>
    </AccordionItem>
  )
}

