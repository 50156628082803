import { Loadout } from "../../types/types";
import {
  FR76Single, SnowpackDuo, Yeager, JS400Single,
  MantisTurret, MantisWingFixed, MSD423Thunderbolts,
  ZeroRushDuo, JS300Single, MirageGuardian, 
  RevenantTopFixed, DRModelXJ1FixedWing, MSD322Dominators, JS500Duo, ChillMaxDuo, FR86Single, Tarantula2WingGimbal, TarantulaWingFixed, CombineTurret, Atlas
} from "../presets";

const drake: Loadout[] = [
  {
    name: "Cutlass Black",
    notes: [

    ],
    avionics: {
      powerplants: JS400Single,
      coolers: SnowpackDuo,
      shields: FR76Single,
      quantumdrive: Yeager
    },
    weaponry: {
      turrets: [
        {
          key: 1,
          name: "Manned 2xS3",
          hardpoints: [
            MantisTurret(1), MantisTurret(2)
          ]
        },
      ],
      hardpoints: [
        MantisWingFixed(1),
        MantisWingFixed(2),
        MantisWingFixed(3),
        MantisWingFixed(4)
      ]
    },
    missiles: [
      MSD423Thunderbolts(1),
      MSD423Thunderbolts(2),
      MSD423Thunderbolts(3),
      MSD423Thunderbolts(4),
      MSD423Thunderbolts(5),
      MSD423Thunderbolts(6),
    ],
    alternatives: []
  },
  {
    name: "Buccaneer",
    notes: [],
    avionics: {
      powerplants: JS300Single,
      coolers: ZeroRushDuo,
      shields: MirageGuardian,
      quantumdrive: Atlas
    },
    weaponry: {
      turrets: [],
      hardpoints: [
        DRModelXJ1FixedWing(1),
        DRModelXJ1FixedWing(2),
        MantisWingFixed(3),
        MantisWingFixed(4),
        RevenantTopFixed(5)
      ]
    },
    missiles: [
      MSD322Dominators(1), MSD322Dominators(2)
    ],
    alternatives: []
  },
  {
    name: "Caterpillar",
    notes: [],
    avionics: {
      powerplants: JS500Duo,
      coolers: ChillMaxDuo,
      shields: FR86Single,
      quantumdrive: "Pontes"
    },
    weaponry: {
      turrets: [
        {
          key: 1,
          name: "Manned 2xS4",
          hardpoints: [
            CombineTurret(1),
            CombineTurret(2)
          ]
        },
        {
          key: 2,
          name: "Manned 2xS4",
          hardpoints: [
            CombineTurret(1),
            CombineTurret(2)
          ]
        },
        {
          key: 3,
          name: "Turret 2xS3",
          hardpoints: [
            TarantulaWingFixed(1),
            TarantulaWingFixed(2)
          ]
        }
      ],
      hardpoints: [
        Tarantula2WingGimbal(1),
        Tarantula2WingGimbal(2)
      ]
    },
    missiles: [],
    alternatives: []
  },
]

export default drake;