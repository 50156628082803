import { Loadout } from "../../types/types";

const crus:Loadout[] = [
  {
    name: "Mercury Star Runner",
    notes:[],
    avionics: {
      powerplants: [
        { key: 1, value: "Dilligence" },
        { key: 1, value: "Dilligence" },
      ],
      coolers: [
        { key: 1, value: "CoolCore" },
        { key: 2, value: "CoolCore" }
      ],
      shields: [
        { key: 1, value: "Aspis" },
        { key: 2, value: "Aspis" }
      ],
      quantumdrive: "Bolon",
    },
    weaponry: {
      turrets: [
        {
          key: 1,
          name: "Manned 2xS3",
          hardpoints: [
            {
              key: 1,
              weapon: "CF-337 Panther Repeater",
              name: "Turret",
              type: "Turret",
              size: 3,
            },
            {
              key: 2,
              weapon: "CF-337 Panther Repeater",
              name: "Turret",
              type: "Turret",
              size: 3,
            }
          ]
        },
        {
          key: 2,
          name: "Manned 2xS3",
          hardpoints: [
            {
              key: 1,
              weapon: "CF-337 Panther Repeater",
              name: "Turret",
              type: "Turret",
              size: 3,
            },
            {
              key: 2,
              weapon: "CF-337 Panther Repeater",
              name: "Turret",
              type: "Turret",
              size: 3,
            }
          ]
        },
      ],
      hardpoints: [
        {
          key: 1,
          name: "Nose",
          type: "Gimbal",
          size: 3,
          weapon: "CF-337 Panther Repeater",
        },
        {
          key: 2,
          name: "Nose",
          type: "Gimbal",
          size: 3,
          weapon: "CF-337 Panther Repeater",
        },
      ]
    },
    missiles: [
      {
        key: 1,
        rack: "MSD-322 Missile Rack",
        missiles: [
          { key: 1, value: "Tempest II" },
          { key: 2, value: "Tempest II" },
        ]
      },
      {
        key: 2,
        rack: "MSD-322 Missile Rack",
        missiles: [
          { key: 1, value: "Tempest II" },
          { key: 2, value: "Tempest II" },
        ]
      },
      {
        key: 3,
        rack: "MSD-313 Missile Rack",
        missiles: [
          { key: 1, value: "Arrester III" },
        ]
      },
      {
        key: 4,
        rack: "MSD-313 Missile Rack",
        missiles: [
          { key: 1, value: "Arrester III" },
        ]
      },
    ],
    alternatives: []
  },
  {
    name: "Hercules Starlifter C2",
    notes:[],
    avionics: {
      powerplants: [
        { key: 1, value: "Ginzel" }
      ],
      coolers: [
        { key: 1, value: "ThermalCore" },
        { key: 2, value: "ThermalCore" }
      ],
      shields: [
        { key: 1, value: "Stronghold" },
        { key: 2, value: "Stronghold" }
      ],
      quantumdrive: "Kama",
    },
    weaponry: {
      turrets: [
        {
          key: 1,
          name: "Remote 2xS3",
          hardpoints: [
            {
              key: 1,
              weapon: "M6A Cannon",
              name: "Turret",
              type: "Turret",
              size: 4,
            },
            {
              key: 2,
              weapon: "M6A Cannon",
              name: "Turret",
              type: "Turret",
              size: 4,
            }
          ]
        }
      ],
      hardpoints: [
        {
          key: 1,
          name: "Nose",
          type: "Gimbal",
          size: 4,
          weapon: "Revenant Gatling",
        },
        {
          key: 2,
          name: "Nose",
          type: "Gimbal",
          size: 4,
          weapon: "Revenant Gatling",
        },
      ]
    },
    missiles: [],
    alternatives: []
  },
  {
    name: "Hercules Starlifter M2",
    notes:[],
    avionics: {
      powerplants: [
        { key: 1, value: "SuperDrive" }
      ],
      coolers: [
        { key: 1, value: "Mercury" },
        { key: 2, value: "Mercury" }
      ],
      shields: [
        { key: 1, value: "FullBlock" },
        { key: 2, value: "FullBlock" }
      ],
      quantumdrive: "Pontes",
    },
    weaponry: {
      turrets: [
        {
          key: 1,
          name: "Remote 2xS3",
          hardpoints: [
            {
              key: 1,
              weapon: "M6A Cannon",
              name: "Turret",
              type: "Turret",
              size: 4,
            },
            {
              key: 2,
              weapon: "M6A Cannon",
              name: "Turret",
              type: "Turret",
              size: 4,
            }
          ]
        },
        {
          key: 2,
          name: "Remote 2xS3",
          hardpoints: [
            {
              key: 1,
              weapon: "M6A Cannon",
              name: "Turret",
              type: "Turret",
              size: 4,
            },
            {
              key: 2,
              weapon: "M6A Cannon",
              name: "Turret",
              type: "Turret",
              size: 4,
            }
          ]
        },
        {
          key: 3,
          name: "Remote 2xS3",
          hardpoints: [
            {
              key: 1,
              weapon: "M6A Cannon",
              name: "Turret",
              type: "Turret",
              size: 4,
            },
            {
              key: 2,
              weapon: "M6A Cannon",
              name: "Turret",
              type: "Turret",
              size: 4,
            }
          ]
        }
      ],
      hardpoints: [
        {
          key: 1,
          name: "Nose",
          type: "Gimbal",
          size: 4,
          weapon: "Revenant Gatling",
        },
        {
          key: 2,
          name: "Nose",
          type: "Gimbal",
          size: 4,
          weapon: "Revenant Gatling",
        },
      ]
    },
    missiles: [],
    alternatives: []
  },
]

export default crus;