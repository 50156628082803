import { Loadout } from "../../types/types";
import {
  MirageGuardian, SlipstreamSingle, Voyage,
  ZeroRushDuo, MantisWingFixed, RevenantNoseFixed,
  MSD341Arrows, AltJS300, NeedOC, NeedJS300, Spectre,
  MirageDuo, ScorpionWingFixed, EclipseTyphoons,
  DRModelXJ3FixedNose, EclipseDuo, SnowpackDuo,
  Yeager, FR76Duo, BadgerTurret, ATVSFixedNose,
  GVSRFixedNose, CombineNoseGimbal, MSD423Thunderbolts,
  VanguardStalkers,
  TarantulaTurret,
  ScorpionTurret,
  Retaliator929,
  Retaliator949,
  MirageDuoGuardian,
  TarantulaWingFixed,
  JS500Duo,
  ChillMaxDuo,
  FR86Duo,
  CombineTurret,
  MSD543Thunderbolts, MSD313Thunderbolt, Atlas
} from "../presets";

const aegis: Loadout[] = [
  {
    name: "Avenger Titan",
    notes: [
      NeedOC, NeedJS300,
    ],
    avionics: {
      powerplants: SlipstreamSingle,
      coolers: ZeroRushDuo,
      quantumdrive: Atlas,
      shields: MirageGuardian
    },
    weaponry: {
      turrets: [],
      hardpoints: [
        MantisWingFixed(1), MantisWingFixed(2), RevenantNoseFixed(3)
      ]
    },
    missiles: [
      MSD341Arrows(1), MSD341Arrows(2)
    ],
    alternatives: [
      AltJS300(1)
    ]
  },
  {
    name: "Avenger Stalker",
    notes: [
      NeedOC, NeedJS300,
    ],
    avionics: {
      powerplants: SlipstreamSingle,
      coolers: ZeroRushDuo,
      quantumdrive: Voyage,
      shields: MirageGuardian
    },
    weaponry: {
      turrets: [],
      hardpoints: [
        MantisWingFixed(1),
        MantisWingFixed(2),
        RevenantNoseFixed(3),
      ]
    },
    missiles: [
      MSD341Arrows(1),
      MSD341Arrows(2),
    ],
    alternatives: [
      AltJS300(1)
    ]
  },
  {
    name: "Eclipse",
    notes: [],
    avionics: {
      powerplants: SlipstreamSingle,
      coolers: ZeroRushDuo,
      shields: MirageDuo,
      quantumdrive: Spectre,
    },
    weaponry: {
      turrets: [],
      hardpoints: [
        ScorpionWingFixed(1),
        ScorpionWingFixed(2),
      ]
    },
    missiles: [
      EclipseTyphoons(1),
    ],
    alternatives: []
  },
  {
    name: "Gladius",
    notes: [
      NeedOC
    ],
    avionics: {
      powerplants: SlipstreamSingle,
      coolers: ZeroRushDuo,
      shields: MirageGuardian,
      quantumdrive: Voyage,
    },
    weaponry: {
      turrets: [],
      hardpoints: [
        MantisWingFixed(1),
        MantisWingFixed(2),
        DRModelXJ3FixedNose(3),
      ],
    },
    missiles: [
      MSD313Thunderbolt(1),
      MSD313Thunderbolt(2),
      MSD313Thunderbolt(3),
      MSD313Thunderbolt(4),
    ],
    alternatives: []
  },
  {
    name: "Vanguard Harbinger",
    notes: [],
    avionics: {
      powerplants: EclipseDuo,
      coolers: SnowpackDuo,
      quantumdrive: Yeager,
      shields: FR76Duo
    },
    weaponry: {
      turrets: [
        {
          key: 1,
          name: "Manned 2xS2",
          hardpoints: [
            BadgerTurret(1),
            BadgerTurret(2)
          ]
        },
      ],
      hardpoints: [
        ATVSFixedNose(1), ATVSFixedNose(2), GVSRFixedNose(3), GVSRFixedNose(4), CombineNoseGimbal(5),
      ],
    },
    missiles: [
      MSD423Thunderbolts(1), MSD423Thunderbolts(2),
      MSD423Thunderbolts(3), MSD423Thunderbolts(4),
      VanguardStalkers(5)
    ],
    alternatives: []
  },
  {
    name: "Retaliator",
    notes: [],
    avionics: {
      powerplants: EclipseDuo,
      coolers: SnowpackDuo,
      quantumdrive: Yeager,
      shields: FR76Duo
    },
    weaponry: {
      turrets: [
        {
          key: 1,
          name: "Manned 2xS3",
          hardpoints: [
            TarantulaTurret(1),
            TarantulaTurret(2)
          ]
        },
        {
          key: 1,
          name: "Manned 2xS3",
          hardpoints: [
            TarantulaTurret(1),
            TarantulaTurret(2)
          ]
        },
        {
          key: 3,
          name: "Manned 2xS3",
          hardpoints: [
            TarantulaTurret(1),
            TarantulaTurret(2)
          ]
        },
        {
          key: 4,
          name: "Manned 2xS2",
          hardpoints: [
            ScorpionTurret(1),
            ScorpionTurret(2)
          ]
        },
        {
          key: 5,
          name: "Manned 2xS2",
          hardpoints: [
            ScorpionTurret(1),
            ScorpionTurret(2)
          ]
        },
      ],
      hardpoints: [],
    },
    missiles: [
      Retaliator929(1),
      Retaliator949(2)
    ],
    alternatives: []
  },
  {
    name: "Sabre",
    notes:[],
    avionics: {
      powerplants: [
        { key: 1, value: "Slipstream" },
        { key: 2, value: "Slipstream" },
      ],
      coolers: ZeroRushDuo,
      quantumdrive: Atlas,
      shields: MirageDuoGuardian
    },
    weaponry: {
      turrets: [],
      hardpoints: [
        TarantulaWingFixed(1),
        TarantulaWingFixed(2),
        TarantulaWingFixed(3),
        TarantulaWingFixed(4)
      ],
    },
    missiles: [
      MSD423Thunderbolts(1),
      MSD423Thunderbolts(2)
    ],
    alternatives: []
  },
  {
    name: "Vanguard Sentinel",
    notes:[
      NeedOC
    ],
    avionics: {
      powerplants: EclipseDuo,
      coolers: SnowpackDuo,
      quantumdrive: Yeager,
      shields: FR76Duo
    },
    weaponry: {
      turrets: [
        {
          key: 1,
          name: "Manned 2xS2",
          hardpoints: [
            BadgerTurret(1),
            BadgerTurret(2)
          ]
        },
      ],
      hardpoints: [
        ATVSFixedNose(1),
        ATVSFixedNose(2),
        GVSRFixedNose(3),
        GVSRFixedNose(4),
        CombineNoseGimbal(5),
      ],
    },
    missiles: [
      MSD423Thunderbolts(1),
      MSD423Thunderbolts(2),
      MSD423Thunderbolts(3),
      MSD423Thunderbolts(4),
    ],
    alternatives: []
  },
  {
    name: "Vanguard Warden",
    notes:[],
    avionics: {
      powerplants: EclipseDuo,
      coolers: SnowpackDuo,
      quantumdrive: Yeager,
      shields: FR76Duo
    },
    weaponry: {
      turrets: [
        {
          key: 1,
          name: "Manned 2xS2",
          hardpoints: [
            BadgerTurret(1),
            BadgerTurret(2)
          ]
        },
      ],
      hardpoints: [
        ATVSFixedNose(1),
        ATVSFixedNose(2),
        GVSRFixedNose(3),
        GVSRFixedNose(4),
        CombineNoseGimbal(5),
      ],
    },
    missiles: [
      MSD423Thunderbolts(1),
      MSD423Thunderbolts(2),
      MSD423Thunderbolts(3),
      MSD423Thunderbolts(4),
    ],
    alternatives: []
  },
  {
    name: "Hammerhead",
    notes: [],
    avionics: {
      powerplants: JS500Duo,
      coolers: ChillMaxDuo,
      shields: FR86Duo,
      quantumdrive: "Pontes"
    },
    weaponry: {
      turrets: [
        {
          key: 1,
          name: "Manned 4xS4",
          hardpoints: [
            CombineTurret(1),
            CombineTurret(2),
            CombineTurret(3),
            CombineTurret(4)
          ]
        },
        {
          key: 2,
          name: "Manned 4xS4",
          hardpoints: [
            CombineTurret(1),
            CombineTurret(2),
            CombineTurret(3),
            CombineTurret(4)
          ]
        },
        {
          key: 3,
          name: "Manned 4xS4",
          hardpoints: [
            CombineTurret(1),
            CombineTurret(2),
            CombineTurret(3),
            CombineTurret(4)
          ]
        },
        {
          key: 4,
          name: "Manned 4xS4",
          hardpoints: [
            CombineTurret(1),
            CombineTurret(2),
            CombineTurret(3),
            CombineTurret(4)
          ]
        },
        {
          key: 5,
          name: "Manned 4xS4",
          hardpoints: [
            CombineTurret(1),
            CombineTurret(2),
            CombineTurret(3),
            CombineTurret(4)
          ]
        },
        {
          key: 6,
          name: "Manned 4xS4",
          hardpoints: [
            CombineTurret(1),
            CombineTurret(2),
            CombineTurret(3),
            CombineTurret(4)
          ]
        },
      ],
      hardpoints: []
    },
    missiles: [
      MSD543Thunderbolts(1),
      MSD543Thunderbolts(2),
      MSD543Thunderbolts(3),
      MSD543Thunderbolts(4),
      MSD543Thunderbolts(5),
      MSD543Thunderbolts(6),
      MSD543Thunderbolts(7),
      MSD543Thunderbolts(8)
    ],
    alternatives: []
  },
]

export default aegis;