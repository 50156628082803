import { Loadout } from "../../types/types";
import {
  FR66Single, SlipstreamSingle, ZeroRushDuo,
  MantisWingFixed, SNT222Dominators, SNT242Dominators,
  NeedOC, MirageGuardian, DRModelXJ3FixedNose, MSD221Arrows,
  MSD313Thunderbolt, SNT143Thunderbolts,
  RevenantNoseFixed,
  Yeager,
  JS500Single,
  ChillMaxSingle,
  FR86Single,
  TarantulaTurret,
  CombineWingGimbal, CombineTopGimbal, SNT543Thunderbolts, Atlas
} from "../presets";

const origin: Loadout[] = [
  {
    name: "100i",
    notes: [],
    avionics: {
      powerplants: SlipstreamSingle,
      coolers: ZeroRushDuo,
      shields: FR66Single,
      quantumdrive: Atlas
    },
    weaponry: {
      turrets: [],
      hardpoints: [
        MantisWingFixed(1), MantisWingFixed(2)
      ]
    },
    missiles: [
      SNT222Dominators(1),
    ],
    alternatives: []
  },
  {
    name: "125a",
    notes: [],
    avionics: {
      powerplants: SlipstreamSingle,
      coolers: ZeroRushDuo,
      shields: FR66Single,
      quantumdrive: Atlas
    },
    weaponry: {
      turrets: [],
      hardpoints: [
        MantisWingFixed(1),
        MantisWingFixed(2)
      ]
    },
    missiles: [
      SNT222Dominators(1),
      SNT242Dominators(2),
    ],
    alternatives: []
  },
  {
    name: "135c",
    notes: [],
    avionics: {
      powerplants: SlipstreamSingle,
      coolers: ZeroRushDuo,
      shields: FR66Single,
      quantumdrive: Atlas
    },
    weaponry: {
      turrets: [],
      hardpoints: [
        MantisWingFixed(1),
        MantisWingFixed(2)
      ]
    },
    missiles: [
      SNT222Dominators(1),
    ],
    alternatives: []
  },
  {
    name: "300i",
    notes: [
      NeedOC
    ],
    avionics: {
      powerplants: SlipstreamSingle,
      coolers: ZeroRushDuo,
      shields: MirageGuardian,
      quantumdrive: Atlas
    },
    weaponry: {
      turrets: [],
      hardpoints: [
        MantisWingFixed(1),
        MantisWingFixed(2),
        DRModelXJ3FixedNose(3)
      ]
    },
    missiles: [
      MSD221Arrows(1),
      MSD221Arrows(2),
    ],
    alternatives: []
  },
  {
    name: "315p",
    notes: [],
    avionics: {
      powerplants: SlipstreamSingle,
      coolers: ZeroRushDuo,
      shields: MirageGuardian,
      quantumdrive: Atlas
    },
    weaponry: {
      turrets: [],
      hardpoints: [
        MantisWingFixed(1),
        MantisWingFixed(2),
      ]
    },
    missiles: [
      MSD221Arrows(1), MSD221Arrows(2)
    ],
    alternatives: []
  },
  {
    name: "325a",
    notes: [
      NeedOC
    ],
    avionics: {
      powerplants: SlipstreamSingle,
      coolers: ZeroRushDuo,
      shields: MirageGuardian,
      quantumdrive: Atlas
    },
    weaponry: {
      turrets: [],
      hardpoints: [
        MantisWingFixed(1),
        MantisWingFixed(2),
        RevenantNoseFixed(3)
      ]
    },
    missiles: [
      MSD313Thunderbolt(1),
      MSD313Thunderbolt(2),
      SNT143Thunderbolts(3),
    ],
    alternatives: []
  },
  {
    name: "350r",
    notes: [
      NeedOC
    ],
    avionics: {
      powerplants: SlipstreamSingle,
      coolers: ZeroRushDuo,
      shields: MirageGuardian,
      quantumdrive: Atlas
    },
    weaponry: {
      turrets: [],
      hardpoints: [
        MantisWingFixed(1),
        MantisWingFixed(2),
        DRModelXJ3FixedNose(3)
      ]
    },
    missiles: [
      MSD221Arrows(1),
      MSD221Arrows(2),
    ],
    alternatives: []
  },
  {
    name: "600i",
    notes: [],
    avionics: {
      powerplants: JS500Single,
      coolers: ChillMaxSingle,
      shields: FR86Single,
      quantumdrive: Yeager
    },
    weaponry: {
      turrets: [
        {
          key: 1,
          name: "Remote 2xS3",
          hardpoints: [
            TarantulaTurret(1),
            TarantulaTurret(2)
          ]
        },
        {
          key: 2,
          name: "Remote 2xS3",
          hardpoints: [
            TarantulaTurret(1),
            TarantulaTurret(2)
          ]
        }
      ],
      hardpoints: [
        CombineWingGimbal(1),
        CombineWingGimbal(2),
        CombineTopGimbal(3)
      ]
    },
    missiles: [
      SNT543Thunderbolts(1),
      SNT543Thunderbolts(2),
      SNT543Thunderbolts(3),
      SNT543Thunderbolts(4)
    ],
    alternatives: []
  },
]

export default origin;