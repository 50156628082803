import { Loadout } from "../types/types";
import aegis from "./stock/aegis";
import anvil from "./stock/anvil";
import banu from "./stock/alien";
import cnou from "./stock/cnou";
import crus from "./stock/crus";
import drake from "./stock/drake";
import misc from "./stock/misc";
import origin from "./stock/origin";
import rsi from "./stock/rsi";

const stock: Loadout[] = [ 
  {
    name: "",
    notes:[],
    avionics: {
      powerplants: [],
      coolers: [],
      shields: [],
      quantumdrive: ""
    },
    weaponry: {
      turrets: [],
      hardpoints: []
    },
    missiles: [],
    alternatives: []
  },
  ...aegis, ...anvil, 
  ...cnou, ...drake, ...misc, 
  ...rsi, ...origin, ...crus,
  ...banu
]

export default stock