import { Checkbox } from "@chakra-ui/checkbox";
import { Box, Heading, HStack } from "@chakra-ui/layout";
import { SyntheticEvent } from "react";

type SaleItemProps = {
  item: string;
  quantity: number;
  price: string;
  bought: boolean;
  boughtSetter: (item: string, bought: boolean) => void;
};
export function SaleItem(props: SaleItemProps) {
  const handleToggle = (e: SyntheticEvent) => {
    //e.preventDefault();
    props.boughtSetter(props.item, !props.bought);
  };

  return (
    <HStack key={Math.random()} spacing='20pt'>
      <Box minWidth='200pt' textAlign='left' fontSize='sm' textDecoration={props.bought ? 'line-through' : 'none'} >{`${props.item} - Qty: ${props.quantity} - ${props.price}aUEC`}</Box>
      <HStack hidden={props.bought}>
        <Heading size='xs' as='h5'>I bought this here: </Heading>
        <Checkbox onClick={handleToggle} />
      </HStack>  
    </HStack>
  );
}
