import { Loadout } from "../../types/types";
import { FR76Duo, JS400Single, SnowpackDuo, Yeager,
ScorpionTurret, MantisTurret, MSD423Thunderbolts, MSD313Thunderbolt } from "../presets";

const misc:Loadout[] = [
  {
    name: "Freelancer",
    notes: [],
    avionics: {
      powerplants: JS400Single,
      coolers: SnowpackDuo,
      shields: FR76Duo,
      quantumdrive: Yeager
    },
    weaponry: {
      turrets: [
        {
          key: 1,
          name: "Manned 2xS2",
          hardpoints: [
            ScorpionTurret(1), ScorpionTurret(2)
          ]
        },
        {
          key: 2,
          name: "Side 2xS3",
          hardpoints: [
            MantisTurret(1), MantisTurret(2)
          ]
        },
        {
          key: 3,
          name: "Side 2xS3",
          hardpoints: [
            MantisTurret(1), MantisTurret(2)
          ]
        },
      ],
      hardpoints: []
    },
    missiles: [
      MSD423Thunderbolts(1),
      MSD423Thunderbolts(2),
      MSD313Thunderbolt(3),
      MSD313Thunderbolt(4)
    ],
    alternatives: []
  },
]

export default misc;