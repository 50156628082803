import { Loadout } from "../../types/types";
import {
  CombineWingFixed, FR76Single, MirageGuardian,
  MSD322Dominators, SlipstreamSingle, SnowpackDuo,
  TarantulaWingFixed, VK00, Yeager,
  ZeroRushDuo, EclipseSingle, TarantulaTurret, CombineWingGimbal, ScorpionWingFixed, MSD683Thunderbolts, JS300Duo, Atlas
} from "../presets";

const banu: Loadout[] = [
  {
    name: "Defender",
    notes: [],
    avionics: {
      powerplants: JS300Duo,
      coolers: ZeroRushDuo,
      quantumdrive: VK00,
      shields: FR76Single
    },
    weaponry: {
      turrets: [],
      hardpoints: [
        TarantulaWingFixed(1),
        TarantulaWingFixed(2),
        TarantulaWingFixed(3),
        TarantulaWingFixed(4)
      ],
    },
    missiles: [
      MSD322Dominators(1),
      MSD322Dominators(2)
    ],
    alternatives: []
  },
  {
    name: "Khartu-Al",
    notes: [],
    avionics: {
      powerplants: SlipstreamSingle,
      coolers: ZeroRushDuo,
      quantumdrive: Atlas,
      shields: MirageGuardian
    },
    weaponry: {
      turrets: [],
      hardpoints: [
        CombineWingFixed(1),
        CombineWingFixed(2)
      ],
    },
    missiles: [],
    alternatives: []
  },
  {
    name: "Prowler",
    notes: [],
    avionics: {
      powerplants: EclipseSingle,
      coolers: SnowpackDuo,
      quantumdrive: Yeager,
      shields: [
        { key: 1, value: "FR-76" },
        { key: 2, value: "Sukoran" },
      ]
    },
    weaponry: {
      turrets: [
        {
          key: 1,
          name: "Turret 2xS3",
          hardpoints: [
            TarantulaTurret(1),
            TarantulaTurret(2)
          ]
        }
      ],
      hardpoints: [
        CombineWingGimbal(1),
        CombineWingGimbal(2),
      ],
    },
    missiles: [],
    alternatives: []
  },
  {
    name: "Talon",
    notes: [],
    avionics: {
      powerplants: SlipstreamSingle,
      coolers: ZeroRushDuo,
      quantumdrive: Atlas,
      shields: MirageGuardian
    },
    weaponry: {
      turrets: [],
      hardpoints: [
        CombineWingFixed(1),
        CombineWingFixed(2)
      ],
    },
    missiles: [
      MSD322Dominators(1),
      MSD322Dominators(2)
    ],
    alternatives: []
  },
  {
    name: "Talon Shrike",
    notes: [],
    avionics: {
      powerplants: SlipstreamSingle,
      coolers: ZeroRushDuo,
      quantumdrive: Atlas,
      shields: MirageGuardian
    },
    weaponry: {
      turrets: [],
      hardpoints: [
        ScorpionWingFixed(1),
        ScorpionWingFixed(2)
      ],
    },
    missiles: [
      MSD683Thunderbolts(1),
      MSD683Thunderbolts(2)
    ],
    alternatives: []
  },
]

export default banu;