import { Alternative, Hardpoint, KeyValue, Missiles } from "../types/types";

// Coolers
export const ZeroRushSingle: KeyValue<string>[] = [
  { key: 1, value: "ZeroRush" },
]

export const ZeroRushDuo: KeyValue<string>[] = [
  { key: 1, value: "ZeroRush" },
  { key: 2, value: "ZeroRush" }
]

export const SnowpackDuo: KeyValue<string>[] = [
  { key: 1, value: "Snowpack" },
  { key: 2, value: "Snowpack" }
]

// Power
export const SlipstreamSingle: KeyValue<string>[] = [
  { key: 1, value: "Slipstream" },
]

export const EclipseDuo: KeyValue<string>[] = [
  { key: 1, value: "Eclipse" },
  { key: 2, value: "Eclipse" },
]

export const EclipseSingle: KeyValue<string>[] = [
  { key: 1, value: "Eclipse" },
]

export const JS400Single: KeyValue<string>[] = [
  { key: 1, value: "JS-400" },
]

export const JS300Single: KeyValue<string>[] = [
  { key: 1, value: "JS-300" },
]

export const JS300Duo:KeyValue<string>[] = [
  { key: 1, value: "JS-300" },
  { key: 2, value: "JS-300" }
]

// Shields
export const Mirage: KeyValue<string>[] = [
  { key: 1, value: "Mirage" },
]

export const MirageGuardian: KeyValue<string>[] = [
  { key: 1, value: "Mirage" },
  { key: 2, value: "Guardian" },
]

export const MirageDuoGuardian: KeyValue<string>[] = [
  { key: 1, value: "Mirage" },
  { key: 2, value: "Mirage" },
  { key: 3, value: "Guardian" },
]

export const MirageDuo: KeyValue<string>[] = [
  { key: 1, value: "Mirage" },
  { key: 2, value: "Mirage" },
]

export const FR76Duo: KeyValue<string>[] = [
  { key: 1, value: "FR-76" },
  { key: 2, value: "FR-76" },
]

export const FR76Single: KeyValue<string>[] = [
  { key: 1, value: "FR-76" },
]

export const FR66Single: KeyValue<string>[] = [
  { key: 1, value: "FR-66" },
]

export const ChillMaxSingle: KeyValue<string>[] = [
  { key: 1, value: "Chill-Max" },
]

export const ChillMaxDuo: KeyValue<string>[] = [
  { key: 1, value: "Chill-Max" },
  { key: 2, value: "Chill-Max" },
]

export const JS500Single: KeyValue<string>[] = [
  { key: 1, value: "JS-500" },
]

export const JS500Duo: KeyValue<string>[] = [
  { key: 1, value: "JS-500" },
  { key: 2, value: "JS-500" },
]

export const FR86Single: KeyValue<string>[] = [
  { key: 1, value: "FR-86" },
]

export const FR86Duo: KeyValue<string>[] = [
  { key: 1, value: "FR-86" },
  { key: 2, value: "FR-86" },
]

// Quantum drive
export const Voyage: string = "Voyage"
export const Spectre: string = "Spectre"
export const Yeager: string = "Yeager"
export const Atlas: string = "Atlas"
export const XL1: string = "XL-1"
export const VK00: string = "VK-00"

// Hardpoints
// Have to be functions for the random keys
export const MantisWingFixed = (key:number): Hardpoint => {
  return {
    key: key,
    name: "Wing",
    type: "Fixed",
    size: 3,
    weapon: "Mantis GT-220 Gatling"
  }
}

export const MantisTurret = (key:number): Hardpoint => {
  return {
    key: key,
    name: "Turret",
    type: "Turret",
    size: 3,
    weapon: "Mantis GT-220 Gatling"
  }
}

export const ScorpionWingFixed = (key:number): Hardpoint => ({
  key: key,
  name: "Wing",
  type: "Fixed",
  size: 2,
  weapon: "Scorpion GT-215 Gatling"
})

export const ScoripionNoseGimbal = (key:number): Hardpoint => ({
  key: key,
  name: "Nose",
  type: "Gimbal",
  size: 2,
  weapon: "Scorpion GT-215 Gatling"
})

export const ScorpionTurret = (key:number): Hardpoint => ({
  key: key,
  name: "Turret",
  type: "Turret",
  size: 2,
  weapon: "Scorpion GT-215 Gatling"
})

export const RevenantNoseFixed = (key:number): Hardpoint => ({
  key: key,
  name: "Nose",
  type: "Fixed",
  size: 4,
  weapon: "Revenant Gatling"
})

export const RevenantTopFixed = (key:number): Hardpoint => ({
  key: key,
  name: "Top",
  type: "Fixed",
  size: 4,
  weapon: "Revenant Gatling"
})

export const RevenantTurret = (key:number): Hardpoint => ({
  key: key,
  name: "Turret",
  type: "Turret",
  size: 4,
  weapon: "Revenant Gatling"
})

export const DRModelXJ3FixedNose = (key:number): Hardpoint => ({
  key: key,
  name: "Nose",
  type: "Fixed",
  size: 3,
  weapon: "DR Model-XJ3 Repeater"
})

export const DRModelXJ1FixedWing = (key:number): Hardpoint => ({
  key: key,
  name: "Wing",
  type: "Fixed",
  size: 1,
  weapon: "DR Model-XJ1 Repeater"
})

export const DRModelXJ3FixedTop = (key:number): Hardpoint => ({
  key: key,
  name: "Top",
  type: "Fixed",
  size: 3,
  weapon: "DR Model-XJ3 Repeater"
})

export const BadgerTurret = (key:number): Hardpoint => ({
  key: key,
  weapon: "CF-227 Badger Repeater",
  name: "Turret",
  type: "Turret",
  size: 2,
})

export const BadgerWingFixed = (key:number): Hardpoint => ({
  key: key,
  weapon: "CF-227 Badger Repeater",
  name: "Wing",
  type: "Fixed",
  size: 2,
})

export const PantherNoseGimbal = (key:number): Hardpoint => ({
  key: key,
  weapon: "CF-337 Panther Repeater",
  name: "Nose",
  type: "Gimbal",
  size: 2,
})

export const ATVSFixedNose = (key:number): Hardpoint => ({
  key: key,
  name: "Nose",
  type: "Fixed",
  size: 2,
  weapon: "ATVS Repeater"
})

export const GVSRFixedNose = (key:number): Hardpoint => ({
  key: key,
  name: "Nose",
  type: "Fixed",
  size: 2,
  weapon: "GVSR Repeater"
})

export const CombineNoseGimbal = (key:number): Hardpoint => ({
  key: key,
  name: "Nose",
  type: "Gimbal",
  size: 4,
  weapon: "C-788 Cannon"
})

export const CombineTurret = (key:number): Hardpoint => ({
  key: key,
  name: "Turret",
  type: "Turret",
  size: 4,
  weapon: "C-788 Cannon"
})

export const CombineWingGimbal = (key:number): Hardpoint => ({
  key: key,
  name: "Wing",
  type: "Gimbal",
  size: 4,
  weapon: "C-788 Cannon"
})

export const CombineTopGimbal = (key:number): Hardpoint => ({
  key: key,
  name: "Top",
  type: "Gimbal",
  size: 4,
  weapon: "C-788 Cannon"
})

export const CombineNoseFixed = (key:number): Hardpoint => ({
  key: key,
  name: "Nose",
  type: "Fixed",
  size: 4,
  weapon: "C-788 Cannon"
})

export const CombineWingFixed = (key:number): Hardpoint => ({
  key: key,
  name: "Wing",
  type: "Fixed",
  size: 4,
  weapon: "C-788 Cannon"
})

export const YellowjacketWingFixed = (key:number): Hardpoint => ({
  key: key,
  name: "Wing",
  type: "Fixed",
  size: 1,
  weapon: "Yellowjacket GT-210 Gatling"
})

export const YellowjacketNoseFixed = (key:number): Hardpoint => ({
  key: key,
  name: "Nose",
  type: "Fixed",
  size: 1,
  weapon: "Yellowjacket GT-210 Gatling"
})

export const YellowjacketTurret = (key:number): Hardpoint => ({
  key: key,
  name: "Turret",
  type: "Turret",
  size: 1,
  weapon: "Yellowjacket GT-210 Gatling"
})

export const TarantulaTurret = (key:number): Hardpoint => ({
  key: key,
  name: "Turret",
  type: "Turret",
  size: 3,
  weapon: "Tarantula GT-870 Mark 3 Cannon"
})

export const Tarantula2WingGimbal = (key:number): Hardpoint => ({
  key: key,
  name: "Wing",
  type: "Gimbal",
  size: 2,
  weapon: "Tarantula GT-870 Mark 2 Cannon"
})

export const TarantulaWingFixed = (key:number): Hardpoint => ({
  key: key,
  name: "Turret",
  type: "Turret",
  size: 3,
  weapon: "Tarantula GT-870 Mark 3 Cannon"
})

// Racks
export const MSD341Arrows = (key:number): Missiles => ({
  key: key,
  rack: "MSD-341 Missile Rack",
  missiles: [
    { key: 1, value: "'Arrow' I" },
    { key: 2, value: "'Arrow' I" },
    { key: 3, value: "'Arrow' I" },
    { key: 4, value: "'Arrow' I" }
  ]
})

export const MSD442Dominators = (key:number):Missiles => ({
  key: key,
  rack: "MSD-442 Missile Rack",
  missiles: [
    { key: 1, value: "Dominator II" },
    { key: 2, value: "Dominator II" },
    { key: 3, value: "Dominator II" },
    { key: 4, value: "Dominator II" },
  ]
})

export const MSD423Thunderbolts = (key:number): Missiles => ({
  key: key,
  rack: "MSD-423 Missile Rack",
  missiles: [
    { key: 1, value: "Thunderbolt III" },
    { key: 2, value: "Thunderbolt III" },
  ]
})

export const MSD322Dominators = (key:number): Missiles => ({
  key: key,
  rack: "MSD-322 Missile Rack",
  missiles: [
    { key: 1, value: "Dominator II" },
    { key: 2, value: "Dominator II" },
  ]
})

export const MSD313Thunderbolt = (key:number): Missiles => ({
  key: key,
  rack: "MSD-313 Missile Rack",
  missiles: [
    { key: 1, value: "Thunderbolt III" },
  ]
})

export const MSD221Arrows = (key:number): Missiles => ({
  key: key,
  rack: "MSD-322 Missile Rack",
  missiles: [
    { key: 1, value: "'Arrow' I" },
    { key: 2, value: "'Arrow' I" },
  ]
})

export const EclipseTyphoons = (key:number): Missiles => ({
  key: key,
  rack: "Eclipse-939 Missile Rack",
  missiles: [
    { key: 1, value: "Typhoon IX Torpedo" },
    { key: 2, value: "Typhoon IX Torpedo" },
    { key: 3, value: "Typhoon IX Torpedo" },
  ]
})

export const Retaliator929 = (key:number): Missiles => ({
  key: key,
  rack: "Eclipse-939 Missile Rack",
  missiles: [
    { key: 1, value: "Typhoon IX Torpedo" },
    { key: 2, value: "Typhoon IX Torpedo" },
  ]
})

export const Retaliator949 = (key:number): Missiles => ({
  key: key,
  rack: "Eclipse-939 Missile Rack",
  missiles: [
    { key: 1, value: "Typhoon IX Torpedo" },
    { key: 2, value: "Typhoon IX Torpedo" },
    { key: 3, value: "Typhoon IX Torpedo" },
    { key: 4, value: "Typhoon IX Torpedo" },
  ]
})

export const VanguardStalkers = (key:number): Missiles => ({
  key: 5,
  rack: "Vanguard-535 Missile Rack",
  missiles: [
    { key: 1, value: "Stalker V" },
    { key: 2, value: "Stalker V" },
    { key: 3, value: "Stalker V" },
  ]
})


export const MSD111Arrows = (key:number): Missiles => ({
  key: key,
  rack: "MSD-111 Missile Rack",
  missiles: [
    { key: 1, value: "'Arrow' I" },
  ]
})

export const SNT222Dominators = (key:number): Missiles => ({
  key: key,
  rack: "SNT-222 Missile Rack",
  missiles: [
    { key: 1, value: "Dominator II" },
    { key: 2, value: "Dominator II" },
  ]
})

export const SNT242Dominators = (key:number): Missiles => ({
  key: key,
  rack: "SNT-242 Missile Rack",
  missiles: [
    { key: 1, value: "Dominator II" },
    { key: 2, value: "Dominator II" },
    { key: 3, value: "Dominator II" },
    { key: 4, value: "Dominator II" },
  ]
})

export const MSD212Dominator = (key:number): Missiles => ({
  key: key,
  rack: "MSD-212 Missile Rack",
  missiles: [
    { key: 1, value: "Dominator II" },
  ]
})

export const SNT143Thunderbolts = (key:number): Missiles => ({
  key: key,
  rack: "SNT-143 Missile Rack",
  missiles: [
    { key: 1, value: "Thunderbolt III" },
    { key: 2, value: "Thunderbolt III" },
    { key: 3, value: "Thunderbolt III" },
    { key: 4, value: "Thunderbolt III" },
  ]
})

export const SNT543Thunderbolts = (key:number): Missiles => ({
  key: key,
  rack: "SNT-543 Missile Rack",
  missiles: [
    { key: 1, value: "Thunderbolt III" },
    { key: 2, value: "Thunderbolt III" },
    { key: 3, value: "Thunderbolt III" },
    { key: 4, value: "Thunderbolt III" },
  ]
})

export const MSD543Thunderbolts = (key:number): Missiles => ({
  key: key,
  rack: "MSD-543 Missile Rack",
  missiles: [
    { key: 1, value: "Thunderbolt III" },
    { key: 2, value: "Thunderbolt III" },
    { key: 3, value: "Thunderbolt III" },
    { key: 4, value: "Thunderbolt III" },
  ]
})

export const Constellation562Dominators = (key:number):Missiles => ({
  key: key,
  rack: "Constellation-562",
  missiles: [
    { key: 1, value: "Dominator II" },
    { key: 2, value: "Dominator II" },
    { key: 3, value: "Dominator II" },
    { key: 4, value: "Dominator II" },
    { key: 5, value: "Dominator II" },
    { key: 6, value: "Dominator II" },
  ]
})

export const Constellation471Arrows = (key:number):Missiles => ({
  key: key,
  rack: "Constellation-471",
  missiles: [
    { key: 1, value: "'Arrow' I" },
    { key: 2, value: "'Arrow' I" },
    { key: 3, value: "'Arrow' I" },
    { key: 4, value: "'Arrow' I" },
    { key: 5, value: "'Arrow' I" },
    { key: 6, value: "'Arrow' I" },
    { key: 7, value: "'Arrow' I" },
  ]
})

export const MSD683Thunderbolts = (key:number):Missiles => ({
  key: 1,
  rack: "MSD-683 Missile Rack",
  missiles: [
    { key: 1, value: "Thunderbolt III" },
    { key: 2, value: "Thunderbolt III" },
    { key: 3, value: "Thunderbolt III" },
    { key: 4, value: "Thunderbolt III" },
    { key: 5, value: "Thunderbolt III" },
    { key: 6, value: "Thunderbolt III" },
    { key: 7, value: "Thunderbolt III" },
    { key: 8, value: "Thunderbolt III" },
    { key: 9, value: "Thunderbolt III" },
    { key: 10, value: "Thunderbolt III" },
    { key: 11, value: "Thunderbolt III" },
    { key: 12, value: "Thunderbolt III" },
  ]
})

// Alternatives
export const AltJS300 = (key:number):Alternative => ({
  key: key,
  type: "Power plant",
  name: "JS-300"
})

// Notes
export const NeedOC: string = "Some weapon combinations require that you overclock the power plant."
export const NeedJS300: string = "Some weapon combinations require that you swap out the Slipstream power plant for a JS-300."

