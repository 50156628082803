import { Loadout } from "../../types/types";

const misc:Loadout[] = [
  {
    name: "Freelancer",
    notes:[],
    avionics: {
      powerplants: [
        { key: 1, value: "DayBreak" },
      ],
      coolers: [
        { key: 1, value: "Frost-Star EX" },
        { key: 2, value: "Frost-Star EX" }
      ],
      shields: [
        { key: 1, value: "5MA 'Chimalli'" },
        { key: 2, value: "5MA 'Chimalli'" }
      ],
      quantumdrive: "Odyssey"
    },
    weaponry: {
      turrets: [
        {
          key: 1,
          name: "Manned 2xS2",
          hardpoints: [
            {
              key: 1,
              weapon: "M4A",
              name: "Turret",
              type: "Turret",
              size: 2,
            },
            {
              key: 2,
              weapon: "M4A",
              name: "Turret",
              type: "Turret",
              size: 2,
            }
          ]
        },
        {
          key: 2,
          name: "Side 2xS3",
          hardpoints: [
            {
              key: 1,
              weapon: "Tarantula GT-870 Mk3 Cannon",
              name: "Turret",
              type: "Turret",
              size: 3,
            },
            {
              key: 2,
              weapon: "Tarantula GT-870 Mk3 Cannon",
              name: "Turret",
              type: "Turret",
              size: 3,
            }
          ]
        },
        {
          key: 3,
          name: "Side 2xS3",
          hardpoints: [
            {
              key: 1,
              weapon: "Tarantula GT-870 Mk3 Cannon",
              name: "Turret",
              type: "Turret",
              size: 3,
            },
            {
              key: 2,
              weapon: "Tarantula GT-870 Mk3 Cannon",
              name: "Turret",
              type: "Turret",
              size: 3,
            }
          ]
        },
      ],
      hardpoints: []
    },
    missiles: [
      {
        key: 1,
        rack: "MSD-423 Missile Rack",
        missiles: [
          { key: 1, value: "Arrester III" },
          { key: 2, value: "Arrester III" },
        ]
      },
      {
        key: 2,
        rack: "MSD-423 Missile Rack",
        missiles: [
          { key: 1, value: "Arrester III" },
          { key: 2, value: "Arrester III" },
        ]
      },
      {
        key: 3,
        rack: "MSD-322",
        missiles: [
          { key: 1, value: "Ignite 2" },
          { key: 2, value: "Ignite 2" },
        ]
      },
      {
        key: 4,
        rack: "MSD-322",
        missiles: [
          { key: 1, value: "Ignite 2" },
          { key: 2, value: "Ignite 2" },
        ]
      }
    ],
    alternatives: []
  },
]

export default misc;
