import { Loadout } from "../types/types";
import origin from "./custom/origin";
import aegis from "./custom/aegis";
import anvil from "./custom/anvil";
import cnou from "./custom/cnou";
import drake from "./custom/drake";
import misc from "./custom/misc";
import rsi from "./custom/rsi";
import crus from "./custom/crus";
import { orderBy } from "lodash";
import banu from "./custom/alien";

const builds: Loadout[] = [
  ...aegis, ...anvil, 
  ...cnou, ...drake, ...misc, 
  ...rsi, ...origin, ...crus,
  ...banu
]

export default orderBy(builds, (b) => b.name)