import { Box, Divider, SimpleGrid, Text, VStack } from '@chakra-ui/layout';
import React from 'react';
import { Missiles } from "../../types/types";

type MissilesProps = {
  missiles: Missiles[]
}
export function MissilesDisplay(props: MissilesProps) {
  return (
    <Box minWidth='200pt'>
      <VStack>
        {props.missiles.map(r => <RackDisplay rack={r} key={r.key} />)}
      </VStack>
    </Box>
  )
}

type RackProps = {
  rack: Missiles
}
function RackDisplay(props: RackProps) {
  return (
    <>
      <Text fontSize='sm' fontWeight='bold'>{props.rack.rack}</Text>
      <Box border='1pt solid white' borderRadius='3pt' padding={1} minWidth='200pt'>
        <SimpleGrid columns={2} spacing='2pt'>
          {props.rack.missiles.map(h => <Box key={h.key}><MissileDisplay missile={h.value} count={0} /></Box>)}
        </SimpleGrid>
      </Box>
      <Divider />
    </>
  )
}

type MissileProps = {
  missile: string
  count: number
}
function MissileDisplay(props: MissileProps) {
  return (
    <Box border='1pt solid white' borderRadius='3pt' padding={1}>
      <Text fontSize='sm'>{`${props.missile}`}</Text>
    </Box>
  )
}