import { Loadout } from "../../types/types";
import { MirageGuardian, NeedOC, SlipstreamSingle, YellowjacketWingFixed, 
  ZeroRushDuo, MSD221Arrows, EclipseDuo, SnowpackDuo, FR76Duo, Yeager,
ScorpionTurret, CombineWingGimbal, Constellation562Dominators,
Constellation471Arrows, 
Atlas} from "../presets";

const rsi:Loadout[] = [
  {
    name: "Aurora MR",
    notes:[
      NeedOC
    ],
    avionics: {
      powerplants: SlipstreamSingle,
      coolers: ZeroRushDuo,
      quantumdrive: Atlas,
      shields: MirageGuardian
    },
    weaponry: {
      turrets: [],
      hardpoints: [
        YellowjacketWingFixed(1),
        YellowjacketWingFixed(2),
        YellowjacketWingFixed(3),
        YellowjacketWingFixed(4)
      ]
    },
    missiles: [
      MSD221Arrows(1),
    ],
    alternatives: []
  },
  {
    name: "Constellation Andromeda",
    notes:[
      NeedOC
    ],
    avionics: {
      powerplants: EclipseDuo,
      coolers: SnowpackDuo,
      shields: FR76Duo,
      quantumdrive: Yeager
    },
    weaponry: {
      turrets: [
        {
          key: 1,
          name: "Manned 2xS2",
          hardpoints: [
            ScorpionTurret(1),
            ScorpionTurret(2)
          ]
        },
        {
          key: 2,
          name: "Manned 2xS2",
          hardpoints: [
            ScorpionTurret(1),
            ScorpionTurret(2)
          ]
        }
      ],
      hardpoints: [
        CombineWingGimbal(1),
        CombineWingGimbal(2),
        CombineWingGimbal(3),
        CombineWingGimbal(4)
      ]
    },
    missiles: [
      Constellation562Dominators(1),
      Constellation562Dominators(2),
      Constellation562Dominators(3),
      Constellation562Dominators(4),
      Constellation471Arrows(5),
      Constellation471Arrows(6),
      Constellation471Arrows(7),
      Constellation471Arrows(8),
    ],
    alternatives: []
  },
]

export default rsi;