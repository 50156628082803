import { Box, VStack, Heading } from "@chakra-ui/react"
import { Loadout } from '../types/types';
import builds from '../builds/builds';
import Select from 'react-select'

export const customStyles = {
  option: (provided:any, state:any) => ({
    ...provided,
    color: 'black',
    fontSize: '12pt'
  }),
  control: (provided:any, state:any) => ({
    ...provided,
    // none of react-select's styles are passed to <Control />
    //width: 300,
    minWidth: 300,
    fontSize: '12pt'
  }),
  singleValue: (provided:any, state:any) => {
    //const opacity = state.isDisabled ? 0.5 : 1;
    //const transition = 'opacity 300ms';

    return { ...provided};
  }
}

type LoadoutSelectorProps = {
  selectHandler: (loadout:Loadout|undefined) => void
}

export function LoadoutSelector(props:LoadoutSelectorProps){
  const handleChange = (data:any) => {
    props.selectHandler(builds.find(l => l.name === data.value))
  }
  return (
    <Box id='loadout-selector'>
      <VStack>
        <Heading size='md'>Select ship:</Heading>
        <Select styles={customStyles} onChange={handleChange} options={builds.map(b => ({value:b.name, label:b.name}))} />
      </VStack>
    </Box>
  )
}