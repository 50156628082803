import { Loadout } from "../../types/types";

const banu:Loadout[] = [
  {
    name: "Defender",
    notes:[],
    avionics: {
      powerplants: [
        { key: 1, value: "IonBurst" },
        { key: 2, value: "IonBurst" }
      ],
      coolers: [
        { key: 1, value: "Polar" },
        { key: 2, value: "Polar" }
      ],
      quantumdrive: "Beacon",
      shields: [
        { key: 1, value: "Sukoran" }
      ]
    },
    weaponry: {
      turrets: [],
      hardpoints: [
        {
          key: 1,
          name: "Wing",
          type: "Gimbal",
          size: 2,
          weapon: "Singe-2"
        },
        {
          key: 2,
          name: "Wing",
          type: "Gimbal",
          size: 2,
          weapon: "Singe-2"
        },
        {
          key: 3,
          name: "Wing",
          type: "Gimbal",
          size: 2,
          weapon: "Singe-2"
        },
        {
          key: 4,
          name: "Wing",
          type: "Gimbal",
          size: 2,
          weapon: "Singe-2"
        },
      ],
    },
    missiles: [
      {
        key: 1,
        rack: "MSD-322",
        missiles: [
          { key: 1, value: "Ignite II" },
          { key: 2, value: "Ignite II" }
        ]
      },
      {
        key: 2,
        rack: "MSD-322",
        missiles: [
          { key: 1, value: "Ignite II" },
          { key: 2, value: "Ignite II" }
        ]
      },
    ],
    alternatives: []
  },
  {
    name: "Khartu-Al",
    notes:[],
    avionics: {
      powerplants: [
        { key: 1, value: "DynaFlux" },
      ],
      coolers: [
        { key: 1, value: "Polar" },
        { key: 2, value: "Polar" }
      ],
      quantumdrive: "Eos",
      shields: [
        { key: 1, value: "ForceWall" },
        { key: 2, value: "ForceWall" },
      ]
    },
    weaponry: {
      turrets: [],
      hardpoints: [
        {
          key: 1,
          name: "Wing",
          type: "Gimbal",
          size: 3,
          weapon: "CF-337 Panther"
        },
        {
          key: 2,
          name: "Wing",
          type: "Gimbal",
          size: 3,
          weapon: "CF-337 Panther"
        },
      ],
    },
    missiles: [],
    alternatives: []
  },
  {
    name: "Prowler",
    notes:[],
    avionics: {
      powerplants: [
        { key: 1, value: "Cirrus" },
      ],
      coolers: [
        { key: 1, value: "HeatSink" },
        { key: 2, value: "HeatSink" }
      ],
      quantumdrive: "Nova",
      shields: [
        { key: 1, value: "Sukoran" },
        { key: 2, value: "Sukoran" },
      ]
    },
    weaponry: {
      turrets: [
        {
          key:1,
          name: "Turret 2xS3",
          hardpoints: [
            {
              key:1,
              size: 3,
              name: "Turret",
              type: "Turret",
              weapon: "Lightstrike III"
            },
            {
              key:2,
              size: 3,
              name: "Turret",
              type: "Turret",
              weapon: "Lightstrike III"
            }
          ]
        }
      ],
      hardpoints: [
        {
          key: 1,
          name: "Wing",
          type: "Gimbal",
          size: 4,
          weapon: "Deadbolt IV"
        },
        {
          key: 2,
          name: "Wing",
          type: "Gimbal",
          size: 4,
          weapon: "Deadbolt IV"
        },
      ],
    },
    missiles: [],
    alternatives: []
  },
  {
    name: "Talon",
    notes:[],
    avionics: {
      powerplants: [
        { key: 1, value: "SonicLite" },
      ],
      coolers: [
        { key: 1, value: "FlashFreeze" },
        { key: 2, value: "FlashFreeze" }
      ],
      quantumdrive: "Drift",
      shields: [
        { key: 1, value: "Sukoran" },
        { key: 2, value: "Sukoran" },
      ]
    },
    weaponry: {
      turrets: [],
      hardpoints: [
        {
          key: 1,
          name: "Wing",
          type: "Gimbal",
          size: 3,
          weapon: "Lightstrike III"
        },
        {
          key: 2,
          name: "Wing",
          type: "Gimbal",
          size: 3,
          weapon: "Lightstrike III"
        },
      ],
    },
    missiles: [
      {
        key: 1,
        rack: "MSD-322",
        missiles: [
          { key: 1, value: "Tempest II" },
          { key: 2, value: "Tempest II" }
        ]
      },
      {
        key: 2,
        rack: "MSD-322",
        missiles: [
          { key: 1, value: "Tempest II" },
          { key: 2, value: "Tempest II" }
        ]
      },
    ],
    alternatives: []
  },
  {
    name: "Talon Shrike",
    notes:[],
    avionics: {
      powerplants: [
        { key: 1, value: "SonicLite" },
      ],
      coolers: [
        { key: 1, value: "FlashFreeze" },
        { key: 2, value: "FlashFreeze" }
      ],
      quantumdrive: "Drift",
      shields: [
        { key: 1, value: "Sukoran" },
        { key: 2, value: "Sukoran" },
      ]
    },
    weaponry: {
      turrets: [],
      hardpoints: [
        {
          key: 1,
          name: "Wing",
          type: "Gimbal",
          size: 1,
          weapon: "Lightstrike I"
        },
        {
          key: 2,
          name: "Wing",
          type: "Gimbal",
          size: 1,
          weapon: "Lightstrike I"
        },
      ],
    },
    missiles: [
      {
        key: 1,
        rack: "MSD-683",
        missiles: [
          { key: 1, value: "Arrester III" },
          { key: 2, value: "Arrester III" },
          { key: 3, value: "Arrester III" },
          { key: 4, value: "Arrester III" },
          { key: 5, value: "Arrester III" },
          { key: 6, value: "Arrester III" },
          { key: 7, value: "Arrester III" },
          { key: 8, value: "Arrester III" },
          { key: 9, value: "Arrester III" },
          { key: 10, value: "Arrester III" },
          { key: 11, value: "Arrester III" },
          { key: 12, value: "Arrester III" },
        ]
      },
      {
        key: 2,
        rack: "MSD-683",
        missiles: [
          { key: 1, value: "Arrester III" },
          { key: 2, value: "Arrester III" },
          { key: 3, value: "Arrester III" },
          { key: 4, value: "Arrester III" },
          { key: 5, value: "Arrester III" },
          { key: 6, value: "Arrester III" },
          { key: 7, value: "Arrester III" },
          { key: 8, value: "Arrester III" },
          { key: 9, value: "Arrester III" },
          { key: 10, value: "Arrester III" },
          { key: 11, value: "Arrester III" },
          { key: 12, value: "Arrester III" },
        ]
      },
    ],
    alternatives: []
  },
]

export default banu;