import { Loadout } from "../../types/types";
import {
  MantisWingFixed, SlipstreamSingle, ZeroRushDuo,
  FR66Single, MSD341Arrows,
  AltJS300, DRModelXJ3FixedTop, NeedOC, NeedJS300,
  MSD221Arrows, Mirage, YellowjacketWingFixed, YellowjacketNoseFixed,
  MSD111Arrows,
  MirageGuardian, DRModelXJ3FixedNose, CombineTurret, MSD322Dominators, TarantulaTurret,
  TarantulaWingFixed, CombineNoseFixed, MSD313Thunderbolt, JS300Single,
  DRModelXJ1FixedWing, BadgerWingFixed, MantisTurret, Yeager, 
  EclipseDuo, SnowpackDuo, FR76Duo, YellowjacketTurret, ScoripionNoseGimbal, 
  JS500Single, ChillMaxDuo, FR86Duo, RevenantTurret, Atlas
} from "../presets";

const anvil: Loadout[] = [
  {
    name: "Arrow",
    notes: [
      NeedOC,
      NeedJS300
    ],
    avionics: {
      powerplants: SlipstreamSingle,
      coolers: ZeroRushDuo,
      quantumdrive: Atlas,
      shields: FR66Single
    },
    weaponry: {
      turrets: [],
      hardpoints: [
        MantisWingFixed(1),
        MantisWingFixed(2),
        DRModelXJ3FixedTop(3)
      ]
    },
    missiles: [
      MSD322Dominators(1), MSD322Dominators(2)
    ],
    alternatives: [
      AltJS300(1)
    ]
  },
  {
    name: "C8X Pisces Expedition",
    notes: [
      NeedOC
    ],
    avionics: {
      powerplants: SlipstreamSingle,
      coolers: ZeroRushDuo,
      shields: Mirage,
      quantumdrive: Atlas
    },
    weaponry: {
      turrets: [],
      hardpoints: [
        YellowjacketWingFixed(1),
        YellowjacketWingFixed(2),
        YellowjacketNoseFixed(3),
        YellowjacketNoseFixed(4)
      ]
    },
    missiles: [
      MSD111Arrows(1), MSD111Arrows(2)
    ],
    alternatives: []
  },
  {
    name: "F7C Hornet",
    notes: [
      NeedOC, NeedJS300
    ],
    avionics: {
      powerplants: SlipstreamSingle,
      coolers: ZeroRushDuo,
      shields: MirageGuardian,
      quantumdrive: Atlas
    },
    weaponry: {
      turrets: [
        {
          key: 1,
          name: "C4-160f S5 Hornet Ball Turret",
          hardpoints: [
            CombineTurret(1)
          ]
        },
      ],
      hardpoints: [
        MantisWingFixed(1),
        MantisWingFixed(2),
        DRModelXJ3FixedNose(3),
      ]
    },
    missiles: [
      MSD322Dominators(1),
      MSD322Dominators(2),
      MSD221Arrows(3),
      MSD221Arrows(4),
    ],
    alternatives: [
      AltJS300(1)
    ]
  },
  {
    name: "F7C-M Super Hornet",
    notes: [
      NeedOC
    ],
    avionics: {
      powerplants: SlipstreamSingle,
      coolers: ZeroRushDuo,
      shields: MirageGuardian,
      quantumdrive: Atlas
    },
    weaponry: {
      turrets: [
        {
          key: 1,
          name: "C4-160f S5 Hornet Ball Turret",
          hardpoints: [
            CombineTurret(1)
          ]
        },
      ],
      hardpoints: [
        MantisWingFixed(1),
        MantisWingFixed(2),
        DRModelXJ3FixedNose(3),
      ]
    },
    missiles: [
      MSD341Arrows(1),
      MSD341Arrows(2),
    ],
    alternatives: []
  },
  {
    name: "Gladiator",
    notes: [],
    avionics: {
      powerplants: SlipstreamSingle,
      coolers: ZeroRushDuo,
      quantumdrive: Atlas,
      shields: MirageGuardian
    },
    weaponry: {
      turrets: [
        {
          key: 1,
          name: "Manned 2xS3",
          hardpoints: [
            TarantulaTurret(1), TarantulaTurret(2),
          ]
        },
      ],
      hardpoints: [
        TarantulaWingFixed(1),
        TarantulaWingFixed(2),
      ],
    },
    missiles: [
      MSD313Thunderbolt(1), MSD313Thunderbolt(2), MSD313Thunderbolt(3), MSD313Thunderbolt(4)
    ],
    alternatives: []
  },
  {
    name: "Hawk",
    notes: [],
    avionics: {
      powerplants: JS300Single,
      coolers: ZeroRushDuo,
      quantumdrive: Atlas,
      shields: MirageGuardian
    },
    weaponry: {
      turrets: [],
      hardpoints: [
        DRModelXJ1FixedWing(1),
        DRModelXJ1FixedWing(2),
        BadgerWingFixed(3),
        BadgerWingFixed(4),
        BadgerWingFixed(5),
        BadgerWingFixed(6)
      ]
    },
    missiles: [],
    alternatives: []
  },
  {
    name: "Hurricane",
    notes: [],
    avionics: {
      powerplants: SlipstreamSingle,
      coolers: ZeroRushDuo,
      quantumdrive: Atlas,
      shields: MirageGuardian
    },
    weaponry: {
      turrets: [
        {
          key: 1,
          name: "Manned 4xS3",
          hardpoints: [
            TarantulaTurret(1),
            TarantulaTurret(2),
            TarantulaTurret(3),
            TarantulaTurret(4)
          ]
        }
      ],
      hardpoints: [
        CombineNoseFixed(1),
        CombineNoseFixed(2)
      ]
    },
    missiles: [
      MSD221Arrows(1),
      MSD221Arrows(2),
      MSD221Arrows(3),
      MSD221Arrows(4)
    ],
    alternatives: []
  },
  {
    name: "Valkyrie",
    notes: [],
    avionics: {
      powerplants: EclipseDuo,
      coolers: SnowpackDuo,
      quantumdrive: Yeager,
      shields: FR76Duo
    },
    weaponry: {
      turrets: [
        {
          key: 1,
          name: "Manned 2xS3",
          hardpoints: [
            MantisTurret(1),
            MantisTurret(2)
          ]
        },
        {
          key: 2,
          name: "Manned 2xS3",
          hardpoints: [
            MantisTurret(1),
            MantisTurret(2)
          ]
        },
        {
          key: 3,
          name: "Remote S3",
          hardpoints: [
            MantisTurret(1)
          ]
        },
        {
          key: 4,
          name: "Remote S3",
          hardpoints: [
            MantisTurret(1)
          ]
        },
        {
          key: 5,
          name: "Door S1",
          hardpoints: [
            YellowjacketTurret(1)
          ]
        },
        {
          key: 6,
          name: "Door S1",
          hardpoints: [
            YellowjacketTurret(1)
          ]
        }
      ],
      hardpoints: [
        ScoripionNoseGimbal(1),
        ScoripionNoseGimbal(2)
      ]
    },
    missiles: [],
    alternatives: []
  },
  {
    name: "Carrack",
    notes: [],
    avionics: {
      powerplants: JS500Single,
      coolers: ChillMaxDuo,
      shields: FR86Duo,
      quantumdrive: "Balandin"
    },
    weaponry: {
      turrets: [
        {
          key: 1,
          name: "Remote 2xS4",
          hardpoints: [
            CombineTurret(1),
            CombineTurret(2)
          ]
        },
        {
          key: 2,
          name: "Manned 2xS4",
          hardpoints: [
            CombineTurret(1),
            CombineTurret(2)
          ]
        },
        {
          key: 3,
          name: "Manned 2xS4",
          hardpoints: [
            RevenantTurret(1),
            RevenantTurret(2)
          ]
        },
        {
          key: 4,
          name: "Manned 2xS4",
          hardpoints: [
            RevenantTurret(1),
            RevenantTurret(2)
          ]
        },
      ],
      hardpoints: []
    },
    missiles: [],
    alternatives: []
  },
]

export default anvil;