import React, { useState } from 'react';
import { Divider, Heading, HStack } from "@chakra-ui/react"
import './App.css';
import { LoadoutSelector } from './components/LoadoutSelector';
import { LoadoutDisplay } from './components/displays/LoadoutDisplay';
import { Loadout } from './types/types';
import { ShoppingList } from './components/shopping/ShoppingList';
import { Icon } from "@chakra-ui/react"
import { GiAnvilImpact } from 'react-icons/gi';

function App() {
  const [loadout, setLoadout] = useState<Loadout>();

  const handleSelect = (data: Loadout | undefined) => {
    setLoadout(data);
  }

  return (
    <div className="App">
      <header className="App-header">
        <HStack>
          <Heading size='2xl' margin='15pt'>Vulcan</Heading>
          <Icon boxSize='65pt' as={GiAnvilImpact} />
        </HStack>
        <Divider width='75vw' />
        <LoadoutSelector selectHandler={handleSelect} />
        {loadout !== undefined ? <LoadoutDisplay loadout={loadout} /> : ''}
        <Divider width='75vw' marginTop='8pt' />
        {loadout !== undefined ? <ShoppingList loadout={loadout} /> : ''}
      </header>
    </div>
  );
}

export default App;
