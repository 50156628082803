import React from 'react';
import { Box, Divider, Heading, Text, VStack } from "@chakra-ui/react";
import { Alternative, Avionics, KeyValue } from '../../types/types';
import { Icon } from "@chakra-ui/react"
import { BsBoxArrowInRight, BsFillLightningFill, BsShieldShaded } from 'react-icons/bs';
import { IoSnowOutline } from 'react-icons/io5';

type AvionicsProps = {
  avionics: Avionics;
  alternatives: Alternative[];
};
export function AvionicsDisplay(props: AvionicsProps) {
  return (
    <Box minWidth='200pt'>
      <VStack>
        <VStack>
          <Heading size='sm'>Power plant <Icon paddingLeft='3pt' boxSize='14pt' as={BsFillLightningFill}/></Heading>        
          {props.avionics.powerplants.map((p: KeyValue<string>) => <Powerplant
            powerplant={p}
            key={p.key} />)}
        </VStack>
        <Divider />
        <VStack>
          <Heading size='sm'>Shield<Icon paddingLeft='3pt' boxSize='14pt' as={BsShieldShaded}/></Heading>
          {props.avionics.shields.map((s: KeyValue<string>) => <Shield
            shield={s}
            key={s.key} />)}
        </VStack>
        <Divider />
        <VStack>
          <Heading size='sm'>Cooler<Icon paddingLeft='3pt' boxSize='14pt' as={IoSnowOutline}/></Heading>
          {props.avionics.coolers.map((c: KeyValue<string>) => <Cooler
            cooler={c}
            key={c.key} />)}
        </VStack>
        <Divider />
        <VStack>
          <Heading size='sm'>Quantum drive<Icon paddingLeft='3pt' boxSize='14pt' as={BsBoxArrowInRight}/></Heading>
          <Box border='1pt solid white' borderRadius='3pt' padding={1} minWidth='100pt'>
            <Text fontSize='sm'>{props.avionics.quantumdrive}</Text>
          </Box>
        </VStack>
        <Divider />
        {props.alternatives.length !== 0 ? <Alternatives alternatives={props.alternatives} /> : ''}
      </VStack>
    </Box>
  );
}
type PowerplantProps = {
  powerplant: KeyValue<string>;
};
function Powerplant(props: PowerplantProps) {
  return (
    <Box border='1pt solid white' borderRadius='3pt' padding={1} minWidth='100pt'>
      <Text fontSize='sm'>{props.powerplant.value}</Text>
    </Box>
  );
}
export type ShieldProps = {
  shield: KeyValue<string>;
};

export function Shield(props: ShieldProps) {
  return (
    <Box border='1pt solid white' borderRadius='3pt' padding={1} minWidth='100pt'>
      <Text fontSize='sm'>{props.shield.value}</Text>
    </Box>
  )
}

type CoolerProps = {
  cooler: KeyValue<string>;
}
export function Cooler(props: CoolerProps) {
  return (
    <Box border='1pt solid white' borderRadius='3pt' padding={1} minWidth='100pt'>
      <Text fontSize='sm'>{props.cooler.value}</Text>
    </Box>
  )
}

type AlternativesProps = {
  alternatives: Alternative[];
}
export function Alternatives(props: AlternativesProps) {
  return (
    <VStack>
      <Heading size='sm'>Alternatives</Heading>
      <Box border='1pt solid white' borderRadius='3pt' padding={1} minWidth='100pt'>
        {props.alternatives.map(a => <Text fontSize='sm' key={a.key}>{`${a.type} - ${a.name}`}</Text>)}
      </Box>
    </VStack>
  )
}