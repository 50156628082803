import { Box, Divider, Heading } from '@chakra-ui/layout';
import React, { useEffect, useState } from 'react';
import apiClient from '../../apiClient';
import shoppingListGenerator from '../../shoppingListGenerator';
import { Loadout, ShoppingItem, LocatedItem } from '../../types/types';
import { cloneDeep, groupBy, orderBy, sortBy } from 'lodash';
import { Accordion } from '@chakra-ui/accordion';
import { ItemSaleLocation } from './ItemSaleLocation';
import GroupedSaleLocation from '../../types/GroupedSaleLocation';
import ItemPrices from '../../types/ItemPrices';

type ShoppingListProps = {
  loadout: Loadout
}
export function ShoppingList(props: ShoppingListProps) {
  const [sale, setSale] = useState<GroupedSaleLocation[]>();
  const [costEstimate, setCostEstimate] = useState([0, 0]);

  const loadLocations = async (list: ShoppingItem[]): Promise<GroupedSaleLocation[]> => {
    let saleLocations: LocatedItem[] = [];
    let itemPrices: ItemPrices[] = [];
    // Get locations from API
    for (let item of list) {
      let res = await apiClient.GetSaleLocations(item.name);
      let newItemPrice: ItemPrices = {
        item: item.name,
        prices: [],
        low: 0,
        high: 0
      }

      for (let r of res) {
        // Sort locations
        let located: LocatedItem = {
          item: item.name,
          storeName: r.saleLocationName,
          storeLocation: `${r.saleLocationName} - ${r.saleLocationChain}`,
          storeChain: r.saleLocationChain,
          price: r.price,
          quantity: item.quantity,
          bought: false
        };
        saleLocations.push(located);

        // Get prices
        newItemPrice.prices.push(r.price);
      }

      newItemPrice.high = orderBy(newItemPrice.prices, 'desc')[0]
      newItemPrice.low = orderBy(newItemPrice.prices, 'asc')[0]
      itemPrices.push(newItemPrice);
    }
    // Select low/high price
    setCostEstimate([itemPrices.map(p => p.high).reduce((a, b) => a + b), itemPrices.map(p => p.low).reduce((a, b) => a + b)])

    let grouped = groupBy(saleLocations, (a) => a.storeLocation)

    // Convert lodash dictionary into an array
    let locations: GroupedSaleLocation[] = [];
    for (let key in grouped) {
      let newLocation: GroupedSaleLocation = {
        chain: key,
        items: grouped[key]
      }
      locations.push(newLocation);
    }

    return sortBy(locations, [
      function (l) { return l.items.length; },
      function (l) { return l.chain.split('-').reverse()[1]; }
    ]).reverse();
  }

  useEffect(() => {
    let list = shoppingListGenerator.Generate(props.loadout) || [];
    loadLocations(list).then((d) => {
      setSale(d);
    })
  }, [props.loadout])

  const getItems = () => {
    if (sale !== undefined) {
      let r: any[] = [];
      for (let s of sale) {
        if (s.chain === '') continue;
        r.push(
          <ItemSaleLocation key={s.chain} items={s.items} location={s.chain} boughtSetter={setItemBought} />
        )
      }
      return r
    }
  }

  const setItemBought = (item: string, bought: boolean) => {
    let n: GroupedSaleLocation[] = [];
    let copy = cloneDeep(sale);
    for (let s of copy || []) {
      let items = s.items;
      let it = items.find(it => it.item === item)
      if (it !== undefined) {
        it.bought = true;
      }
      let newCopy: GroupedSaleLocation = {
        chain: s.chain,
        items: s.items
      }
      //if (items.every(i => i.bought)) continue;
      n.push(newCopy);
    }
    setSale(n);
  }

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  })

  return (
    <Box id='shopping-list'>
      <Heading size='md' margin='10pt'>Shopping list</Heading>
      <Divider orientation='horizontal' />
      <Box flex="1" fontSize='md' textAlign="left">Estimated total cost: {formatter.format(costEstimate[0])} aUEC - {formatter.format(costEstimate[1])} aUEC</Box>
      {sale !== undefined ? Object.keys(sale).length !== 1 ?
        <Accordion width='75vw' allowMultiple>
          {getItems()}
        </Accordion> : 'All items purchased!' : 'Loading...'
      }
    </Box>
  )
}

