import { Loadout } from "../../types/types";
import {
  MirageGuardian, SlipstreamSingle, ZeroRushDuo,
  ScorpionTurret, YellowjacketWingFixed, AltJS300, NeedOC, NeedJS300,
  MantisWingFixed,
  DRModelXJ3FixedTop, MirageDuoGuardian, MSD442Dominators, Atlas
} from "../presets";

const cnou: Loadout[] = [
  {
    name: "Mustang Alpha",
    notes: [
      NeedOC,
      NeedJS300
    ],
    avionics: {
      powerplants: SlipstreamSingle,
      coolers: ZeroRushDuo,
      shields: MirageGuardian,
      quantumdrive: Atlas
    },
    weaponry: {
      turrets: [
        {
          key: 1,
          name: "Nose 2xS2",
          hardpoints: [
            ScorpionTurret(1), ScorpionTurret(2)
          ]
        },
      ],
      hardpoints: [
        YellowjacketWingFixed(1),
        YellowjacketWingFixed(2),
      ]
    },
    missiles: [],
    alternatives: [
      AltJS300(1)
    ]
  },
  {
    name: "Nomad",
    notes: [
      NeedOC, NeedJS300
    ],
    avionics: {
      powerplants: SlipstreamSingle,
      coolers: ZeroRushDuo,
      shields: MirageDuoGuardian,
      quantumdrive: Atlas
    },
    weaponry: {
      turrets: [],
      hardpoints: [
        MantisWingFixed(1),
        MantisWingFixed(2),
        DRModelXJ3FixedTop(3),
      ]
    },
    missiles: [
      MSD442Dominators(1), MSD442Dominators(2)
    ],
    alternatives: [
      AltJS300(1)
    ]
  },
]

export default cnou;