import { Loadout } from "../../types/types";
import { MantisTurret, YellowjacketTurret, ScoripionNoseGimbal } from "../presets";

const anvil: Loadout[] = [
  {
    name: "Arrow",
    notes: [],
    avionics: {
      powerplants: [
        { key: 1, value: "Regulus" }
      ],
      coolers: [
        { key: 1, value: "Bracer" },
        { key: 2, value: "Bracer" }
      ],
      quantumdrive: "Beacon",
      shields: [
        { key: 1, value: "AllStop" }
      ]
    },
    weaponry: {
      turrets: [
        {
          key: 1,
          name: "Top Turret",
          hardpoints: [
            {
              key: 1,
              name: "Turret 2xS1",
              type: "Gimbal",
              size: 1,
              weapon: "CF-117 Bulldog"
            },
            {
              key: 2,
              name: "Turret 2xS1",
              type: "Gimbal",
              size: 1,
              weapon: "CF-117 Bulldog"
            },
          ]
        }
      ],
      hardpoints: [
        {
          key: 1,
          name: "Wing",
          type: "Gimbal",
          size: 2,
          weapon: "Scorpion GT-215 Gatling"
        },
        {
          key: 2,
          name: "Wing",
          type: "Gimbal",
          size: 2,
          weapon: "Scorpion GT-215 Gatling"
        }
      ]
    },
    missiles: [
      {
        key: 1,
        rack: "MSD-212",
        missiles: [
          { key: 1, value: "Dominator II" },
        ]
      },
      {
        key: 2,
        rack: "MSD-212",
        missiles: [
          { key: 1, value: "Ignite 2" },
        ]
      },
      {
        key: 3,
        rack: "MSD-322",
        missiles: [
          { key: 1, value: "Dominator II" },
          { key: 2, value: "Dominator II" },
        ]
      },
      {
        key: 4,
        rack: "MSD-322",
        missiles: [
          { key: 1, value: "Ignite 2" },
          { key: 2, value: "Ignite 2" },
        ]
      }
    ],
    alternatives: []
  },
  {
    name: "C8X Pisces Expedition",
    notes: [],
    avionics: {
      powerplants: [
        { key: 1, value: "Regulus" }
      ],
      coolers: [
        { key: 1, value: "Bracer" },
        { key: 2, value: "Bracer" }
      ],
      shields: [
        { key: 1, value: "Shimmer" }
      ],
      quantumdrive: "Expedition"
    },
    weaponry: {
      turrets: [],
      hardpoints: [
        {
          key: 1,
          name: "Wing",
          type: "Fixed",
          size: 1,
          weapon: "Omnisky III"
        },
        {
          key: 2,
          name: "Wing",
          type: "Fixed",
          size: 1,
          weapon: "Omnisky III"
        },
        {
          key: 3,
          name: "Nose",
          type: "Gimbal",
          size: 1,
          weapon: "CF-117 Bulldog"
        },
        {
          key: 4,
          name: "Nose",
          type: "Gimbal",
          size: 1,
          weapon: "CF-117 Bulldog"
        }
      ]
    },
    missiles: [
      {
        key: 1,
        rack: "MSD-111",
        missiles: [
          { key: 1, value: "Marksman I" },
        ]
      },
      {
        key: 2,
        rack: "MSD-111",
        missiles: [
          { key: 1, value: "Marksman I" },
        ]
      },
    ],
    alternatives: []
  },
  {
    name: "F7C Hornet",
    notes: [],
    avionics: {
      powerplants: [
        { key: 1, value: "PowerBolt" },
      ],
      coolers: [
        { key: 1, value: "ArcticStorm" },
        { key: 2, value: "ArcticStorm" },
      ],
      shields: [
        { key: 1, value: "WEB" },
        { key: 2, value: "WEB" },
      ],
      quantumdrive: "Eos"
    },
    weaponry: {
      turrets: [],
      hardpoints: [
        {
          key: 1,
          name: "Wing",
          type: "Fixed",
          size: 3,
          weapon: "Mantis GT-220 Gatling"
        },
        {
          key: 2,
          name: "Wing",
          type: "Fixed",
          size: 3,
          weapon: "Mantis GT-220 Gatling"
        },
      ]
    },
    missiles: [
      {
        key: 1,
        rack: "MSD-322",
        missiles: [
          { key: 1, value: "Dominator II" },
          { key: 2, value: "Dominator II" }
        ]
      },
      {
        key: 1,
        rack: "MSD-322",
        missiles: [
          { key: 1, value: "Dominator II" },
          { key: 2, value: "Dominator II" }
        ]
      },
      {
        key: 1,
        rack: "MSD-221",
        missiles: [
          { key: 1, value: "Marksman I" },
          { key: 2, value: "Marksman I" }
        ]
      },
      {
        key: 1,
        rack: "MSD-221",
        missiles: [
          { key: 1, value: "Marksman I" },
          { key: 2, value: "Marksman I" }
        ]
      },
    ],
    alternatives: []
  },
  {
    name: "F7C-M Super Hornet",
    notes: [],
    avionics: {
      powerplants: [
        { key: 1, value: "Regulus" },
      ],
      coolers: [
        { key: 1, value: "Bracer" },
        { key: 2, value: "Bracer" },
      ],
      shields: [
        { key: 1, value: "AllStop" },
        { key: 2, value: "AllStop" },
      ],
      quantumdrive: "Eos"
    },
    weaponry: {
      turrets: [
        {
          name: 'Nose 2xS1',
          key: 1,
          hardpoints: [
            {
              key: 1,
              name: "Nose",
              type: "Turret",
              size: 1,
              weapon: "CF-117 Bulldog"
            },
            {
              key: 2,
              name: "Nose",
              type: "Turret",
              size: 1,
              weapon: "CF-117 Bulldog"
            }
          ]
        },
        {
          name: 'Ball 2xS2',
          key: 2,
          hardpoints: [
            {
              key: 1,
              name: "Nose",
              type: "Turret",
              size: 2,
              weapon: "CF-227 Badger"
            },
            {
              key: 2,
              name: "Nose",
              type: "Turret",
              size: 2,
              weapon: "CF-227 Badger"
            }
          ]
        }
      ],
      hardpoints: [
        {
          key: 1,
          name: "Wing",
          type: "Gimbal",
          size: 2,
          weapon: "Scorpion GT-215 Gatling"
        },
        {
          key: 2,
          name: "Wing",
          type: "Gimbal",
          size: 2,
          weapon: "Scorpion GT-215 Gatling"
        },
      ]
    },
    missiles: [
      {
        key: 1,
        rack: "MSD-341",
        missiles: [
          { key: 1, value: "Marksman I" },
          { key: 2, value: "Marksman I" },
          { key: 3, value: "Marksman I" },
          { key: 4, value: "Marksman I" }
        ]
      },
      {
        key: 1,
        rack: "MSD-341",
        missiles: [
          { key: 1, value: "Marksman I" },
          { key: 2, value: "Marksman I" },
          { key: 3, value: "Marksman I" },
          { key: 4, value: "Marksman I" }
        ]
      },
    ],
    alternatives: []
  },
  {
    name: "Gladiator",
    notes: [],
    avionics: {
      powerplants: [
        { key: 1, value: "" }
      ],
      coolers: [
        { key: 1, value: "" },
        { key: 2, value: "" }
      ],
      quantumdrive: "",
      shields: [
        { key: 1, value: "" },
        { key: 2, value: "" }
      ]
    },
    weaponry: {
      turrets: [],
      hardpoints: [
        {
          key: 1,
          name: "Wing",
          type: "Fixed",
          size: 3,
          weapon: ""
        },
        {
          key: 2,
          name: "Wing",
          type: "Fixed",
          size: 3,
          weapon: ""
        },
        {
          key: 3,
          name: "Wing",
          type: "Fixed",
          size: 3,
          weapon: ""
        },
        {
          key: 4,
          name: "Wing",
          type: "Fixed",
          size: 3,
          weapon: ""
        },
      ],
    },
    missiles: [

    ],
    alternatives: []
  },
  {
    name: "Hawk",
    notes: [],
    avionics: {
      powerplants: [
        { key: 1, value: "Regulus" }
      ],
      coolers: [
        { key: 1, value: "Bracer" },
        { key: 2, value: "Bracer" }
      ],
      quantumdrive: "Rush",
      shields: [
        { key: 1, value: "AllStop" },
        { key: 2, value: "AllStop" }
      ]
    },
    weaponry: {
      turrets: [],
      hardpoints: [
        {
          key: 1,
          name: "Wing",
          type: "Fixed",
          size: 1,
          weapon: "Suckerpunch"
        },
        {
          key: 2,
          name: "Wing",
          type: "Fixed",
          size: 1,
          weapon: "Suckerpunch"
        },
        {
          key: 3,
          name: "Wing",
          type: "Fixed",
          size: 2,
          weapon: "M4A"
        }
        ,
        {
          key: 4,
          name: "Wing",
          type: "Fixed",
          size: 2,
          weapon: "M4A"
        }
        ,
        {
          key: 5,
          name: "Wing",
          type: "Fixed",
          size: 2,
          weapon: "M4A"
        }
        ,
        {
          key: 6,
          name: "Wing",
          type: "Fixed",
          size: 2,
          weapon: "M4A"
        }
      ]
    },
    missiles: [],
    alternatives: []
  },
  {
    name: "Hurricane",
    notes: [],
    avionics: {
      powerplants: [
        { key: 1, value: "Regulus" }
      ],
      coolers: [
        { key: 1, value: "Bracer" },
        { key: 2, value: "Bracer" }
      ],
      quantumdrive: "Expedition",
      shields: [
        { key: 1, value: "AllStop" },
        { key: 2, value: "AllStop" }
      ]
    },
    weaponry: {
      turrets: [
        {
          key: 1,
          name: "Manned 4xS3",
          hardpoints: [
            {
              key: 1,
              name: "Turret",
              type: "Turret",
              size: 3,
              weapon: "11-Series Broadsword"
            },
            {
              key: 2,
              name: "Turret",
              type: "Turret",
              size: 3,
              weapon: "11-Series Broadsword"
            },
            {
              key: 3,
              name: "Turret",
              type: "Turret",
              size: 3,
              weapon: "11-Series Broadsword"
            }, {
              key: 4,
              name: "Turret",
              type: "Turret",
              size: 3,
              weapon: "11-Series Broadsword"
            }
          ]
        }
      ],
      hardpoints: [
        {
          key: 1,
          name: "Nose",
          type: "Gimbal",
          size: 3,
          weapon: "Predator"
        },
        {
          key: 2,
          name: "Nose",
          type: "Gimbal",
          size: 3,
          weapon: "Predator"
        },
      ]
    },
    missiles: [
      {
        key: 1,
        rack: "MSD-212 Missile Rack",
        missiles: [
          { key: 1, value: "Ignite II" }
        ]
      },
      {
        key: 2,
        rack: "MSD-212 Missile Rack",
        missiles: [
          { key: 1, value: "Ignite II" }
        ]
      },
      {
        key: 3,
        rack: "MSD-212 Missile Rack",
        missiles: [
          { key: 1, value: "Ignite II" }
        ]
      },
      {
        key: 4,
        rack: "MSD-212 Missile Rack",
        missiles: [
          { key: 1, value: "Ignite II" }
        ]
      },
    ],
    alternatives: []
  },
  {
    name: "Valkyrie",
    notes: [],
    avionics: {
      powerplants: [
        { key: 1, value: "Maelstrom" },
        { key: 2, value: "Maelstrom" }
      ],
      coolers: [
        { key: 1, value: "Arctic" },
        { key: 2, value: "Arctic" }
      ],
      quantumdrive: "Odyssey",
      shields: [
        { key: 1, value: "FullStop" },
        { key: 2, value: "FullStop" }
      ]
    },
    weaponry: {
      turrets: [
        {
          key: 1,
          name: "Manned 2xS3",
          hardpoints: [
            MantisTurret(1),
            MantisTurret(2)
          ]
        },
        {
          key: 2,
          name: "Manned 2xS3",
          hardpoints: [
            MantisTurret(1),
            MantisTurret(2)
          ]
        },
        {
          key: 3,
          name: "Remote S3",
          hardpoints: [
            MantisTurret(1)
          ]
        },
        {
          key: 4,
          name: "Remote S3",
          hardpoints: [
            MantisTurret(1)
          ]
        },
        {
          key: 5,
          name: "Door S1",
          hardpoints: [
            YellowjacketTurret(1)
          ]
        },
        {
          key: 6,
          name: "Door S1",
          hardpoints: [
            YellowjacketTurret(1)
          ]
        }
      ],
      hardpoints: [
        ScoripionNoseGimbal(1),
        ScoripionNoseGimbal(2)
      ]
    },
    missiles: [],
    alternatives: []
  },
  {
    name: "Carrack",
    notes: [],
    avionics: {
      powerplants: [
        { key: 1, value: "Reliance" },
      ],
      coolers: [
        { key: 1, value: "Ice-Flush" },
        { key: 2, value: "Ice-Flush" },
      ],
      shields: [
        { key: 1, value: "Barbican" },
        { key: 2, value: "Barbican" },
      ],
      quantumdrive: "Agni"
    },
    weaponry: {
      turrets: [
        {
          key: 1,
          name: "Remote 2xS4",
          hardpoints: [
            {
              key: 1,
              name: "Turret",
              type: "Turret",
              size: 4,
              weapon: "M6A"
            },
            {
              key: 2,
              name: "Turret",
              type: "Turret",
              size: 4,
              weapon: "M6A"
            }
          ]
        },
        {
          key: 2,
          name: "Manned 2xS4",
          hardpoints: [
            {
              key: 1,
              name: "Turret",
              type: "Turret",
              size: 4,
              weapon: "M6A"
            },
            {
              key: 2,
              name: "Turret",
              type: "Turret",
              size: 4,
              weapon: "M6A"
            }
          ]
        },
        {
          key: 3,
          name: "Manned 2xS4",
          hardpoints: [
            {
              key: 1,
              name: "Turret",
              type: "Turret",
              size: 4,
              weapon: "CF-447 Rhino"
            },
            {
              key: 2,
              name: "Turret",
              type: "Turret",
              size: 4,
              weapon: "CF-447 Rhino"
            }
          ]
        },
        {
          key: 4,
          name: "Manned 2xS4",
          hardpoints: [
            {
              key: 1,
              name: "Turret",
              type: "Turret",
              size: 4,
              weapon: "CF-447 Rhino"
            },
            {
              key: 2,
              name: "Turret",
              type: "Turret",
              size: 4,
              weapon: "CF-447 Rhino"
            }
          ]
        },
      ],
      hardpoints: []
    },
    missiles: [],
    alternatives: []
  },
];

export default anvil;