import React from 'react';
import { Box, Divider, Heading, HStack, Text, VStack } from "@chakra-ui/react"
import { Loadout } from '../../types/types';
import { AvionicsDisplay } from './AvionicsDisplay';
import { WeaponryDisplay } from './WeaponryDisplay';
import { MissilesDisplay } from './MissilesDisplay';

const avionicsAltTypes = [
  'Power plant',
  'Shield',
  'Cooler',
  'Quantum drive'
]

type LoadoutDisplayProps = {
  loadout: Loadout
}
export function LoadoutDisplay(props: LoadoutDisplayProps) {
  return (
    <Box>
      <Divider padding='10pt'/>
        {props.loadout.notes.map(n => <Text fontSize='sm' key={Math.random()}>{n}</Text>)}
      <HStack id='loadout' alignItems='baseline'>       
        <VStack id='avionics' border='1pt solid whitesmoke' borderRadius='3pt' padding='10pt' margin='5pt'>
          <Heading size='md'>Avionics</Heading>
          <Divider />
          <AvionicsDisplay
            avionics={props.loadout.avionics}
            alternatives={props.loadout.alternatives.filter(a => avionicsAltTypes.includes(a.type))}
          />
        </VStack>
        <Divider orientation='vertical' height='auto' />
        <VStack id='weaponry' border='1pt solid whitesmoke' borderRadius='3pt' padding='10pt' margin='5pt'>
          <Heading size='md'>Weaponry</Heading>
          <Divider />
          <WeaponryDisplay weaponry={props.loadout.weaponry} />
        </VStack>
        <Divider orientation='vertical' />
        <VStack id='missiles' border='1pt solid whitesmoke' borderRadius='3pt' padding='10pt' margin='5pt'>
          <Heading size='md'>Missiles</Heading>
          <Divider />
          <MissilesDisplay missiles={props.loadout.missiles} />
        </VStack>
      </HStack>
    </Box>

  )
}
