import { Loadout } from "../../types/types";

const rsi:Loadout[] = [
  {
    name: "Aurora MR",
    notes:[],
    avionics: {
      powerplants: [
        { key: 1, value: "Roughneck" }
      ],
      coolers: [
        { key: 1, value: "Hydrocel" },
        { key: 2, value: "Hydrocel" }
      ],
      quantumdrive: "Eos",
      shields: [
        { key: 1, value: "Bulwark" },
        { key: 2, value: "Bulwark" }
      ]
    },
    weaponry: {
      turrets: [],
      hardpoints: [
        {
          key: 1,
          name: "Wing",
          type: "Gimbal",
          size: 1,
          weapon: "Yellowjacket GT-210 Gatling"
        },
        {
          key: 2,
          name: "Wing",
          type: "Gimbal",
          size: 1,
          weapon: "Yellowjacket GT-210 Gatling"
        },
      ]
    },
    missiles: [
      {
        key: 1,
        rack: "MSD-221 Missile Rack",
        missiles: [
          { key: 1, value: "Arrow I" },
          { key: 2, value: "Arrow I" }
        ]
      },
    ],
    alternatives: []
  }, 
  {
    name: "Constellation Andromeda",
    notes:[],
    avionics: {
      powerplants: [
        { key: 1, value: "DayBreak" },
        { key: 2, value: "DayBreak" }
      ],
      coolers: [
        { key: 1, value: "Frost-Star EX" },
        { key: 2, value: "Frost-Star EX" }
      ],
      shields: [
        { key: 1, value: "5MA 'Chimalli'" },
        { key: 2, value: "5MA 'Chimalli'" }
      ],
      quantumdrive: "Bolon"
    },
    weaponry: {
      turrets: [
        {
          key: 1,
          name: "Manned 2xS2",
          hardpoints: [
            {
              key: 1,
              weapon: "M4A",
              name: "Turret",
              type: "Turret",
              size: 2,
            },
            {
              key: 2,
              weapon: "M4A",
              name: "Turret",
              type: "Turret",
              size: 2,
            }
          ]
        },
        {
          key: 2,
          name: "Manned 2xS2",
          hardpoints: [
            {
              key: 1,
              weapon: "M4A",
              name: "Turret",
              type: "Turret",
              size: 2,
            },
            {
              key: 2,
              weapon: "M4A",
              name: "Turret",
              type: "Turret",
              size: 2,
            }
          ]
        }
      ],
      hardpoints: [
        {
          key: 1,
          name: "Wing",
          type: "Gimbal",
          size: 4,
          weapon: "CF-447 Rhino"
        },
        {
          key: 2,
          name: "Wing",
          type: "Gimbal",
          size: 4,
          weapon: "CF-447 Rhino"
        },
        {
          key: 3,
          name: "Wing",
          type: "Gimbal",
          size: 4,
          weapon: "CF-447 Rhino"
        },
        {
          key: 4,
          name: "Wing",
          type: "Gimbal",
          size: 4,
          weapon: "CF-447 Rhino"
        }
      ]
    },
    missiles: [
      {
        key: 1,
        rack: "Constellation-562",
        missiles: [
          { key: 1, value: "Strikeforce II" },
          { key: 2, value: "Strikeforce II" },
          { key: 3, value: "Strikeforce II" },
          { key: 4, value: "Strikeforce II" },
          { key: 5, value: "Strikeforce II" },
          { key: 6, value: "Strikeforce II" },
        ]
      },
      {
        key: 2,
        rack: "Constellation-562",
        missiles: [
          { key: 1, value: "Strikeforce II" },
          { key: 2, value: "Strikeforce II" },
          { key: 3, value: "Strikeforce II" },
          { key: 4, value: "Strikeforce II" },
          { key: 5, value: "Strikeforce II" },
          { key: 6, value: "Strikeforce II" },
        ]
      },
      {
        key: 3,
        rack: "Constellation-562",
        missiles: [
          { key: 1, value: "Strikeforce II" },
          { key: 2, value: "Strikeforce II" },
          { key: 3, value: "Strikeforce II" },
          { key: 4, value: "Strikeforce II" },
          { key: 5, value: "Strikeforce II" },
          { key: 6, value: "Strikeforce II" },
        ]
      },
      {
        key: 4,
        rack: "Constellation-562",
        missiles: [
          { key: 1, value: "Strikeforce II" },
          { key: 2, value: "Strikeforce II" },
          { key: 3, value: "Strikeforce II" },
          { key: 4, value: "Strikeforce II" },
          { key: 5, value: "Strikeforce II" },
          { key: 6, value: "Strikeforce II" },
        ]
      },
      {
        key: 5,
        rack: "Constellation-471",
        missiles: [
          { key: 1, value: "Marksman I" },
          { key: 2, value: "Marksman I" },
          { key: 3, value: "Marksman I" },
          { key: 4, value: "Marksman I" },
          { key: 5, value: "Marksman I" },
          { key: 6, value: "Marksman I" },
          { key: 7, value: "Marksman I" },
        ]
      },
      {
        key: 6,
        rack: "Constellation-471",
        missiles: [
          { key: 1, value: "Marksman I" },
          { key: 2, value: "Marksman I" },
          { key: 3, value: "Marksman I" },
          { key: 4, value: "Marksman I" },
          { key: 5, value: "Marksman I" },
          { key: 6, value: "Marksman I" },
          { key: 7, value: "Marksman I" },
        ]
      },
      {
        key: 7,
        rack: "Constellation-471",
        missiles: [
          { key: 1, value: "Marksman I" },
          { key: 2, value: "Marksman I" },
          { key: 3, value: "Marksman I" },
          { key: 4, value: "Marksman I" },
          { key: 5, value: "Marksman I" },
          { key: 6, value: "Marksman I" },
          { key: 7, value: "Marksman I" },
        ]
      },
      {
        key: 8,
        rack: "Constellation-471",
        missiles: [
          { key: 1, value: "Marksman I" },
          { key: 2, value: "Marksman I" },
          { key: 3, value: "Marksman I" },
          { key: 4, value: "Marksman I" },
          { key: 5, value: "Marksman I" },
          { key: 6, value: "Marksman I" },
          { key: 7, value: "Marksman I" },
        ]
      },
    ],
    alternatives: []
  },
]

export default rsi;