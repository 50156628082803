import { Box, Divider, Heading, List, ListItem, Text, VStack } from '@chakra-ui/layout';
import React from 'react';
import { Hardpoint, Turret, Weaponry } from "../../types/types";

type WeaponryProps = {
  weaponry: Weaponry
}
export function WeaponryDisplay(props: WeaponryProps) {
  return (
    <Box minWidth='200pt'>
      <VStack>
        {props.weaponry.turrets.length !== 0 ? <TurretsDisplay turrets={props.weaponry.turrets} /> : ''}
        {props.weaponry.hardpoints.length !== 0 ? <HardpointsDisplay hardpoints={props.weaponry.hardpoints} /> : ''}
      </VStack>
    </Box>
  )
}

type TurretsProps = {
  turrets: Turret[]
}
function TurretsDisplay(props: TurretsProps) {
  return (
    <>
      <VStack>
        <Heading size='sm'>Turrets</Heading>
        {props.turrets.map(t => <TurretDisplay turret={t} key={t.key} />)}
      </VStack>
      <Divider />
    </>
  )
}

type TurretProps = {
  turret: Turret
}
function TurretDisplay(props: TurretProps) {
  return (
    <Box border='1pt solid white' borderRadius='3pt' padding={1} minWidth='200pt'>
      <Text fontSize='sm' fontWeight='bold'>{props.turret.name}</Text>
      <List>
        {props.turret.hardpoints.map(h => <ListItem key={h.key}><WeaponDisplay hardpoint={h} /></ListItem>)}
      </List>
    </Box>
  )
}

type HardpointsProps = {
  hardpoints: Hardpoint[]
}
function HardpointsDisplay(props: HardpointsProps) {
  return (
    <>
      <VStack>
        <Heading size='sm'>Hardpoints</Heading>
        {props.hardpoints.map(t => <HardpointDisplay hardpoint={t} key={t.key} />)}
      </VStack>
      <Divider />
    </>
  )
}

type HardpointProps = {
  hardpoint: Hardpoint
}
function HardpointDisplay(props: HardpointProps) {
  return (
    <Box border='1pt solid white' borderRadius='3pt' padding={1} minWidth='200pt'>
      <Text fontSize='sm' fontWeight='bold'>
        {`${props.hardpoint.name} - Size ${props.hardpoint.size} - ${props.hardpoint.type}`}
      </Text>
      <Text fontSize='sm'>{props.hardpoint.weapon}</Text>
    </Box>
  )
}

type WeaponProps = {
  hardpoint: Hardpoint
}
function WeaponDisplay(props: WeaponProps) {
  return (
    <Box>
      <Text fontSize='sm'>{`Size ${props.hardpoint.size} - ${props.hardpoint.type} - ${props.hardpoint.weapon}`}</Text>
    </Box>
  )
}