import { Loadout } from "../../types/types";

const cnou: Loadout[] = [
  {
    name: "Mustang Alpha",
    notes: [],
    avionics: {
      powerplants: [
        { key: 1, value: "ZapJet" },
      ],
      coolers: [
        { key: 1, value: "Winter-Star" },
        { key: 2, value: "Winter-Star" }
      ],
      shields: [
        { key: 1, value: "INK" },
        { key: 2, value: "INK" }
      ],
      quantumdrive: "Rush"
    },
    weaponry: {
      turrets: [
        {
          key: 1,
          name: "Nose 2xS2",
          hardpoints: [
            {
              key: 1,
              weapon: "M4A",
              name: "Turret",
              type: "Turret",
              size: 2,
            },
            {
              key: 2,
              weapon: "M4A",
              name: "Turret",
              type: "Turret",
              size: 2,
            }
          ]
        },
      ],
      hardpoints: []
    },
    missiles: [],
    alternatives: []
  },
  {
    name: "Nomad",
    notes: [],
    avionics: {
      powerplants: [
        { key: 1, value: "PowerBolt" },
      ],
      coolers: [
        { key: 1, value: "ArcticStorm" },
        { key: 2, value: "ArcticStorm" }
      ],
      shields: [
        { key: 1, value: "WEB" },
        { key: 2, value: "WEB" },
        { key: 3, value: "WEB" }
      ],
      quantumdrive: "Expedition"
    },
    weaponry: {
      turrets: [],
      hardpoints: [
        {
          key: 1,
          name: "Wing",
          type: "Gimbal",
          size: 2,
          weapon: "CF-227 Badger Repeater"
        },
        {
          key: 2,
          name: "Wing",
          type: "Gimbal",
          size: 2,
          weapon: "CF-227 Badger Repeater"
        },
        {
          key: 3,
          name: "Top",
          type: "Gimbal",
          size: 2,
          weapon: "CF-227 Badger Repeater"
        },
      ]
    },
    missiles: [
      {
        key: 1,
        rack: "MSD-442 Missile Rack",
        missiles: [
          { key: 1, value: "StrikeForce II" },
          { key: 2, value: "StrikeForce II" },
          { key: 3, value: "StrikeForce II" },
          { key: 4, value: "StrikeForce II" },
        ]
      },
      {
        key: 2,
        rack: "MSD-442 Missile Rack",
        missiles: [
          { key: 1, value: "StrikeForce II" },
          { key: 2, value: "StrikeForce II" },
          { key: 3, value: "StrikeForce II" },
          { key: 4, value: "StrikeForce II" },
        ]
      },
    ],
    alternatives: []
  },
]

export default cnou;