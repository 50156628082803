import { Loadout } from "../../types/types";

const drake:Loadout[] = [
  {
    name: "Cutlass Black",
    notes:[],
    avionics: {
      powerplants: [{ key: 1, value: "DayBreak" }],
      coolers: [{ key: 1, value: "ColdSnap" }, { key: 2, value: "ColdSnap" }],
      shields: [{ key: 1, value: "STOP" }],
      quantumdrive: "Odyssey"
    },
    weaponry: {
      turrets: [
        {
          key: 1,
          name: "Manned 2xS3",
          hardpoints: [
            {
              key: 1,
              weapon: "CF-337 Panther Repeater",
              name: "Turret",
              type: "Turret",
              size: 3,
            },
            {
              key: 2,
              weapon: "CF-337 Panther Repeater",
              name: "Turret",
              type: "Turret",
              size: 3,
            }
          ]
        },
      ],
      hardpoints: [
        {
          key: 1,
          name: "Wing",
          type: "Gimbal",
          size: 2,
          weapon: "Scorpion GT-215 Gatling"
        },
        {
          key: 2,
          name: "Wing",
          type: "Gimbal",
          size: 2,
          weapon: "Scorpion GT-215 Gatling"
        },
        {
          key: 3,
          name: "Wing",
          type: "Gimbal",
          size: 2,
          weapon: "CF-227 Badger Repeater"
        },
        {
          key: 4,
          name: "Wing",
          type: "Gimbal",
          size: 2,
          weapon: "CF-227 Badger Repeater"
        }
      ]
    },
    missiles: [
      {
        key: 1,
        rack: "MSD-423 Missile Rack",
        missiles: [
          { key: 1, value: "Arrester III" },
          { key: 2, value: "Arrester III" },
        ]
      },
      {
        key: 2,
        rack: "MSD-423 Missile Rack",
        missiles: [
          { key: 1, value: "Arrester III" },
          { key: 2, value: "Arrester III" },
        ]
      },
      {
        key: 3,
        rack: "MSD-423 Missile Rack",
        missiles: [
          { key: 1, value: "Arrester III" },
          { key: 2, value: "Arrester III" },
        ]
      },
      {
        key: 4,
        rack: "MSD-423 Missile Rack",
        missiles: [
          { key: 1, value: "Arrester III" },
          { key: 2, value: "Arrester III" },
        ]
      },
      {
        key: 5,
        rack: "MSD-442 Missile Rack",
        missiles: [
          { key: 1, value: "Tempest II" },
          { key: 2, value: "Tempest II" },
          { key: 3, value: "Tempest II" },
          { key: 4, value: "Tempest II" },
        ]
      },
      {
        key: 6,
        rack: "MSD-442 Missile Rack",
        missiles: [
          { key: 1, value: "Tempest II" },
          { key: 2, value: "Tempest II" },
          { key: 3, value: "Tempest II" },
          { key: 4, value: "Tempest II" },
        ]
      },
    ],
    alternatives: []
  },
  {
    name: "Buccaneer",
    notes:[],
    avionics: {
      powerplants: [
        { key: 1, value: "JS-300" },
      ],
      coolers: [
        { key: 1, value: "Polar" },
        { key: 2, value: "Polar" },
      ],
      shields: [
        { key: 1, value: "WEB" },
        { key: 2, value: "WEB" },
      ],
      quantumdrive: "Rush"
    },
    weaponry: {
      turrets: [],
      hardpoints: [
        {
          key: 1,
          name: "Wing",
          type: "Fixed",
          size: 1,
          weapon: "YellowJacket GT-210 Gatling"
        },
        {
          key: 2,
          name: "Wing",
          type: "Fixed",
          size: 1,
          weapon: "YellowJacket GT-210 Gatling"
        },
        {
          key: 3,
          name: "Wing",
          type: "Fixed",
          size: 3,
          weapon: "CF-337 Panther Repeater"
        },
        {
          key: 4,
          name: "Wing",
          type: "Fixed",
          size: 3,
          weapon: "CF-337 Panther Repeater"
        },
        {
          key: 4,
          name: "Top",
          type: "Fixed",
          size: 4,
          weapon: "Revenant Gatling"
        }
      ]
    },
    missiles: [
      {
        key: 1,
        rack: "MSD-322 Missile Rack",
        missiles: [
          { key: 1, value: "Tempest II" },
          { key: 2, value: "Tempest II" },
        ]
      },
      {
        key: 2,
        rack: "MSD-322 Missile Rack",
        missiles: [
          { key: 1, value: "Ignite II" },
          { key: 2, value: "Ignite II" },
        ]
      },
    ],
    alternatives: []
  },
  {
    name: "Caterpillar",
    notes: [],
    avionics: {
      powerplants: [
        { key: 1, value: "Ginzel" },
        { key: 2, value: "Ginzel" },
      ],
      coolers: [
        { key: 1, value: "ThermalCore" },
        { key: 2, value: "ThermalCore" },
      ],
      shields: [
        { key: 1, value: "Stronghold" },
      ],
      quantumdrive: "Pontes"
    },
    weaponry: {
      turrets: [
        {
          key: 1,
          name: "Manned 2xS4",
          hardpoints: [
            {
              key: 1,
              name: "Turret",
              type: "Turret",
              size: 4,
              weapon: "M6A"
            },
            {
              key: 2,
              name: "Turret",
              type: "Turret",
              size: 4,
              weapon: "M6A"
            }
          ]
        },
        {
          key: 2,
          name: "Manned 2xS4",
          hardpoints: [
            {
              key: 1,
              name: "Turret",
              type: "Turret",
              size: 4,
              weapon: "M6A"
            },
            {
              key: 2,
              name: "Turret",
              type: "Turret",
              size: 4,
              weapon: "M6A"
            }
          ]
        },
        {
          key: 3,
          name: "Turret 2xS3",
          hardpoints: [
            {
              key: 1,
              name: "Turret",
              type: "Turret",
              size: 3,
              weapon: "M5A"
            },
            {
              key: 2,
              name: "Turret",
              type: "Turret",
              size: 3,
              weapon: "M5A"
            }
          ]
        }
      ],
      hardpoints: [
        {
          key: 1,
          weapon: 'M4A',
          name: "Wing",
          type: "Gimbal",
          size: 2,
        },
        {
          key: 2,
          weapon: 'M4A',
          name: "Wing",
          type: "Gimbal",
          size: 2,
        },
      ]
    },
    missiles: [],
    alternatives: []
  },
]

export default drake;