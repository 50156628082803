import { Loadout } from "../../types/types"

const aegis:Loadout[] = [
  {
    name: "Avenger Titan",
    notes:[],
    avionics: {
      powerplants: [
        { key: 1, value: "Regulus" }
      ],
      coolers: [
        { key: 1, value: "Bracer" },
        { key: 2, value: "Bracer" }
      ],
      quantumdrive: "Beacon",
      shields: [
        { key: 1, value: "AllStop" }
      ]
    },
    weaponry: {
      turrets: [],
      hardpoints: [
        {
          key: 1,
          name: "Wing",
          type: "Gimbal",
          size: 2,
          weapon: "CF-227 Badger Repeater"
        },
        {
          key: 2,
          name: "Wing",
          type: "Gimbal",
          size: 2,
          weapon: "CF-227 Badger Repeater"
        },
        {
          key: 3,
          name: "Nose",
          type: "Gimbal",
          size: 3,
          weapon: "Mantis GT-220 Gatling"
        },
      ],
    },
    missiles: [
      {
        key: 1,
        rack: "MSD-322",
        missiles: [
          { key: 1, value: "Ignite II" },
          { key: 2, value: "Ignite II" }
        ]
      },
      {
        key: 2,
        rack: "MSD-322",
        missiles: [
          { key: 1, value: "Ignite II" },
          { key: 2, value: "Ignite II" }
        ]
      },
    ],
    alternatives: []
  },
  {
    name: "Avenger Stalker",
    notes:[],
    avionics: {
      powerplants: [
        { key: 1, value: "Regulus" }
      ],
      coolers: [
        { key: 1, value: "Bracer" },
        { key: 2, value: "Bracer" }
      ],
      quantumdrive: "Beacon",
      shields: [
        { key: 1, value: "AllStop" }
      ]
    },
    weaponry: {
      turrets: [],
      hardpoints: [
        {
          key: 1,
          name: "Wing",
          type: "Gimbal",
          size: 2,
          weapon: "CF-227 Badger Repeater"
        },
        {
          key: 2,
          name: "Wing",
          type: "Gimbal",
          size: 2,
          weapon: "CF-227 Badger Repeater"
        },
        {
          key: 3,
          name: "Nose",
          type: "Gimbal",
          size: 3,
          weapon: "Mantis GT-220 Gatling"
        },
      ],
    },
    missiles: [
      {
        key: 1,
        rack: "MSD-322",
        missiles: [
          { key: 1, value: "Ignite II" },
          { key: 2, value: "Ignite II" }
        ]
      },
      {
        key: 2,
        rack: "MSD-322",
        missiles: [
          { key: 1, value: "Ignite II" },
          { key: 2, value: "Ignite II" }
        ]
      },
    ],
    alternatives: []
  },
  {
    name: "Eclipse",
    notes:[],
    avionics: {
      powerplants: [
        { key: 1, value: "DeltaMax" }
      ],
      coolers: [
        { key: 1, value: "VaporBlock" },
        { key: 2, value: "VaporBlock" }
      ],
      shields: [
        { key: 1, value: "Veil" },
        { key: 2, value: "Veil" }
      ],
      quantumdrive: "Drift",
    },
    weaponry: {
      turrets: [],
      hardpoints: [
        {
          key: 1,
          name: "Wing",
          type: "Fixed",
          size: 2,
          weapon: "Scorpion GT-215 Gatling"
        },
        {
          key: 2,
          name: "Wing",
          type: "Fixed",
          size: 2,
          weapon: "Scorpion GT-215 Gatling"
        },
      ]
    },
    missiles: [
      {
        key: 1,
        rack: "Eclipse-939 Missile Rack",
        missiles: [
          { key: 1, value: "Argos IX" },
          { key: 2, value: "Argos IX" },
          { key: 3, value: "Argos IX" },
        ]
      },
    ],
    alternatives: []
  },
  {
    name: "Gladius",
    notes:[],
    avionics: {
      powerplants: [
        { key: 1, value: "Regulus" }
      ],
      coolers: [
        { key: 1, value: "Bracer" },
        { key: 2, value: "Bracer" }
      ],
      quantumdrive: "Beacon",
      shields: [
        { key: 1, value: "AllStop" },
        { key: 2, value: "AllStop" }
      ]
    },
    weaponry: {
      turrets: [],
      hardpoints: [
        {
          key: 1,
          name: "Wing",
          type: "Fixed",
          size: 3,
          weapon: "CF-337 Panther Repeater"
        },
        {
          key: 2,
          name: "Wing",
          type: "Fixed",
          size: 3,
          weapon: "CF-337 Panther Repeater"
        },
        {
          key: 3,
          name: "Nose",
          type: "Fixed",
          size: 3,
          weapon: "Mantis GT-220 Gatling"
        },
      ],
    },
    missiles: [
      {
        key: 1,
        rack: "MSD-322",
        missiles: [
          { key: 1, value: "Ignite II" },
          { key: 2, value: "Ignite II" }
        ]
      },
      {
        key: 2,
        rack: "MSD-322",
        missiles: [
          { key: 1, value: "Ignite II" },
          { key: 2, value: "Ignite II" }
        ]
      },
      {
        key: 3,
        rack: "MSD-313",
        missiles: [
          { key: 1, value: "Arrester III" },
        ]
      },
      {
        key: 4,
        rack: "MSD-313",
        missiles: [
          { key: 1, value: "Arrester III" },
        ]
      },
    ],
    alternatives: []
  },
  {
    name: "Vanguard Harbinger",
    notes:[],
    avionics: {
      powerplants: [
        { key: 1, value: "TurboDrive" },
        { key: 2, value: "TurboDrive" },
      ],
      coolers: [
        { key: 1, value: "Permafrost" },
        { key: 2, value: "Permafrost" }
      ],
      quantumdrive: "Yeager",
      shields: [
        { key: 1, value: "SecureShield" },
        { key: 2, value: "SecureShield" }
      ]
    },
    weaponry: {
      turrets: [
        {
          key: 1,
          name: "Manned 2xS2",
          hardpoints: [
            {
              key: 1,
              weapon: "Jericho XL",
              name: "Turret",
              type: "Turret",
              size: 2,
            },
            {
              key: 2,
              weapon: "Jericho XL",
              name: "Turret",
              type: "Turret",
              size: 2,
            }
          ]
        },
      ],
      hardpoints: [
        {
          key: 1,
          name: "Nose",
          type: "Fixed",
          size: 2,
          weapon: "CVSA Cannon"
        },
        {
          key: 2,
          name: "Nose",
          type: "Fixed",
          size: 2,
          weapon: "CVSA Cannon"
        },
        {
          key: 3,
          name: "Nose",
          type: "Fixed",
          size: 2,
          weapon: "CVSA Cannon"
        },
        {
          key: 4,
          name: "Nose",
          type: "Fixed",
          size: 2,
          weapon: "CVSA Cannon"
        },
        {
          key: 5,
          name: "Nose",
          type: "Gimbal",
          size: 4,
          weapon: "C-788 Combine"
        },
      ],
    },
    missiles: [
      {
        key: 1,
        rack: "MSD-442",
        missiles: [
          { key: 1, value: "Ignite II" },
          { key: 2, value: "Ignite II" },
          { key: 3, value: "Ignite II" },
          { key: 4, value: "Ignite II" }
        ]
      },
      {
        key: 2,
        rack: "MSD-442",
        missiles: [
          { key: 1, value: "Ignite II" },
          { key: 2, value: "Ignite II" },
          { key: 3, value: "Ignite II" },
          { key: 4, value: "Ignite II" }
        ]
      },
      {
        key: 3,
        rack: "MSD-423",
        missiles: [
          { key: 1, value: "Arrester III" },
          { key: 2, value: "Arrester III" },
        ]
      },
      {
        key: 4,
        rack: "MSD-423",
        missiles: [
          { key: 1, value: "Arrester III" },
          { key: 2, value: "Arrester III" },
        ]
      },
      {
        key: 5,
        rack: "Vanguard-535 Missile Rack",
        missiles: [
          { key: 1, value: "Stalker V" },
          { key: 2, value: "Stalker V" },
          { key: 3, value: "Stalker V" },
        ]
      },
    ],
    alternatives: []
  },
  {
    name: "Retaliator",
    notes:[],
    avionics: {
      powerplants: [
        { key: 1, value: "Maelstrom" },
        { key: 2, value: "Maelstrom" },
      ],
      coolers: [
        { key: 1, value: "Arctic" },
        { key: 2, value: "Arctic" }
      ],
      quantumdrive: "Crossfield",
      shields: [
        { key: 1, value: "FullStop" },
        { key: 2, value: "FullStop" }
      ]
    },
    weaponry: {
      turrets: [
        {
          key: 1,
          name: "Manned 2xS3",
          hardpoints: [
            {
              key: 1,
              weapon: "11-Series Broadsword",
              name: "Turret",
              type: "Turret",
              size: 3,
            },
            {
              key: 2,
              weapon: "11-Series Broadsword",
              name: "Turret",
              type: "Turret",
              size: 3,
            }
          ]
        },
        {
          key: 1,
          name: "Manned 2xS3",
          hardpoints: [
            {
              key: 1,
              weapon: "11-Series Broadsword",
              name: "Turret",
              type: "Turret",
              size: 3,
            },
            {
              key: 2,
              weapon: "11-Series Broadsword",
              name: "Turret",
              type: "Turret",
              size: 3,
            }
          ]
        },
        {
          key: 3,
          name: "Manned 2xS3",
          hardpoints: [
            {
              key: 1,
              weapon: "11-Series Broadsword",
              name: "Turret",
              type: "Turret",
              size: 3,
            },
            {
              key: 2,
              weapon: "11-Series Broadsword",
              name: "Turret",
              type: "Turret",
              size: 3,
            }
          ]
        },
        {
          key: 4,
          name: "Manned 2xS2",
          hardpoints: [
            {
              key: 1,
              weapon: "Scorpion GT-215 Gatling",
              name: "Turret",
              type: "Turret",
              size: 2,
            },
            {
              key: 2,
              weapon: "Scorpion GT-215 Gatling",
              name: "Turret",
              type: "Turret",
              size: 2,
            }
          ]
        },
        {
          key: 5,
          name: "Manned 2xS2",
          hardpoints: [
            {
              key: 1,
              weapon: "Scorpion GT-215 Gatling",
              name: "Turret",
              type: "Turret",
              size: 2,
            },
            {
              key: 2,
              weapon: "Scorpion GT-215 Gatling",
              name: "Turret",
              type: "Turret",
              size: 2,
            }
          ]
        },
      ],
      hardpoints: [],
    },
    missiles: [
      {
        key: 1,
        rack: "Retaliator-929",
        missiles: [
          { key: 1, value: "Argos IX" },
          { key: 2, value: "Argos IX" },
        ]
      },
      {
        key: 2,
        rack: "Retaliator-949",
        missiles: [
          { key: 1, value: "Argos IX" },
          { key: 2, value: "Argos IX" },
          { key: 3, value: "Argos IX" },
          { key: 4, value: "Argos IX" }
        ]
      }
    ],
    alternatives: []
  },
  {
    name: "Sabre",
    notes:[],
    avionics: {
      powerplants: [
        { key: 1, value: "SonicLite" },
        { key: 2, value: "SonicLite" },
      ],
      coolers: [
        { key: 1, value: "Bracer" },
        { key: 2, value: "Bracer" }
      ],
      quantumdrive: "Drift",
      shields: [
        { key: 1, value: "Shimmer" },
        { key: 2, value: "Shimmer" },
        { key: 3, value: "Shimmer" }
      ]
    },
    weaponry: {
      turrets: [],
      hardpoints: [
        {
          key: 1,
          name: "Wing",
          type: "Fixed",
          size: 3,
          weapon: "CF-337 Panther Repeater"
        },
        {
          key: 2,
          name: "Wing",
          type: "Fixed",
          size: 3,
          weapon: "CF-337 Panther Repeater"
        },
        {
          key: 3,
          name: "Nose",
          type: "Fixed",
          size: 3,
          weapon: "CF-337 Panther Repeater"
        },
        {
          key: 4,
          name: "Nose",
          type: "Fixed",
          size: 3,
          weapon: "CF-337 Panther Repeater"
        },
      ],
    },
    missiles: [
      {
        key: 1,
        rack: "MSD-423",
        missiles: [
          { key: 1, value: "Thunderbolt III" },
          { key: 2, value: "Thunderbolt III" }
        ]
      },
      {
        key: 2,
        rack: "MSD-423",
        missiles: [
          { key: 1, value: "Arrester III" },
          { key: 2, value: "Arrester III" }
        ]
      },
    ],
    alternatives: []
  },
  {
    name: "Vanguard Sentinel",
    notes:[],
    avionics: {
      powerplants: [
        { key: 1, value: "GammaMax" },
        { key: 2, value: "GammaMax" },
      ],
      coolers: [
        { key: 1, value: "HeatSink" },
        { key: 2, value: "HeatSink" }
      ],
      quantumdrive: "Nova",
      shields: [
        { key: 1, value: "Sheut" },
        { key: 2, value: "Sheut" }
      ]
    },
    weaponry: {
      turrets: [
        {
          key: 1,
          name: "Manned 2xS2",
          hardpoints: [
            {
              key: 1,
              weapon: "Suckerpunch-L",
              name: "Turret",
              type: "Turret",
              size: 2,
            },
            {
              key: 2,
              weapon: "Suckerpunch-L",
              name: "Turret",
              type: "Turret",
              size: 2,
            }
          ]
        },
      ],
      hardpoints: [
        {
          key: 1,
          name: "Nose",
          type: "Fixed",
          size: 2,
          weapon: "ATVS Cannon"
        },
        {
          key: 2,
          name: "Nose",
          type: "Fixed",
          size: 2,
          weapon: "ATVS Cannon"
        },
        {
          key: 3,
          name: "Nose",
          type: "Fixed",
          size: 2,
          weapon: "ATVS Cannon"
        },
        {
          key: 4,
          name: "Nose",
          type: "Fixed",
          size: 2,
          weapon: "ATVS Cannon"
        },
        {
          key: 5,
          name: "Nose",
          type: "Gimbal",
          size: 4,
          weapon: "Attrition-4"
        },
      ],
    },
    missiles: [
      {
        key: 1,
        rack: "MSD-442",
        missiles: [
          { key: 1, value: "Ignite II" },
          { key: 2, value: "Ignite II" },
          { key: 3, value: "Ignite II" },
          { key: 4, value: "Ignite II" }
        ]
      },
      {
        key: 2,
        rack: "MSD-442",
        missiles: [
          { key: 1, value: "Dominator II" },
          { key: 2, value: "Dominator II" },
          { key: 3, value: "Dominator II" },
          { key: 4, value: "Dominator II" }
        ]
      },
      {
        key: 3,
        rack: "MSD-423",
        missiles: [
          { key: 1, value: "Arrester III" },
          { key: 2, value: "Arrester III" },
        ]
      },
      {
        key: 4,
        rack: "MSD-423",
        missiles: [
          { key: 1, value: "Arrester III" },
          { key: 2, value: "Arrester III" },
        ]
      },
    ],
    alternatives: []
  },
  {
    name: "Vanguard Warden",
    notes:[],
    avionics: {
      powerplants: [
        { key: 1, value: "Maelstrom" },
        { key: 2, value: "Maelstrom" },
      ],
      coolers: [
        { key: 1, value: "Arctic" },
        { key: 2, value: "Arctic" }
      ],
      quantumdrive: "Crossfield",
      shields: [
        { key: 1, value: "FullStop" },
        { key: 2, value: "FullStop" }
      ]
    },
    weaponry: {
      turrets: [
        {
          key: 1,
          name: "Manned 2xS2",
          hardpoints: [
            {
              key: 1,
              weapon: 'SW16BR2 "Sawbuck"',
              name: "Turret",
              type: "Turret",
              size: 2,
            },
            {
              key: 2,
              weapon: 'SW16BR2 "Sawbuck"',
              name: "Turret",
              type: "Turret",
              size: 2,
            }
          ]
        },
      ],
      hardpoints: [
        {
          key: 1,
          name: "Nose",
          type: "Fixed",
          size: 2,
          weapon: "MVSA Cannon"
        },
        {
          key: 2,
          name: "Nose",
          type: "Fixed",
          size: 2,
          weapon: "MVSA Cannon"
        },
        {
          key: 3,
          name: "Nose",
          type: "Fixed",
          size: 2,
          weapon: "MVSA Cannon"
        },
        {
          key: 4,
          name: "Nose",
          type: "Fixed",
          size: 2,
          weapon: "MVSA Cannon"
        },
        {
          key: 5,
          name: "Nose",
          type: "Gimbal",
          size: 4,
          weapon: "Revenant"
        },
      ],
    },
    missiles: [
      {
        key: 1,
        rack: "MSD-442",
        missiles: [
          { key: 1, value: "Ignite II" },
          { key: 2, value: "Ignite II" },
          { key: 3, value: "Ignite II" },
          { key: 4, value: "Ignite II" }
        ]
      },
      {
        key: 2,
        rack: "MSD-442",
        missiles: [
          { key: 1, value: "Dominator II" },
          { key: 2, value: "Dominator II" },
          { key: 3, value: "Dominator II" },
          { key: 4, value: "Dominator II" }
        ]
      },
      {
        key: 3,
        rack: "MSD-423",
        missiles: [
          { key: 1, value: "Arrester III" },
          { key: 2, value: "Arrester III" },
        ]
      },
      {
        key: 4,
        rack: "MSD-423",
        missiles: [
          { key: 1, value: "Arrester III" },
          { key: 2, value: "Arrester III" },
        ]
      },
    ],
    alternatives: []
  },
  {
    name: "Hammerhead",
    notes: [],
    avionics: {
      powerplants: [
        { key: 1, value: "SuperDrive" },
        { key: 2, value: "SuperDrive" },
      ],
      coolers: [
        { key: 1, value: "Mercury" },
        { key: 2, value: "Mercury" },
      ],
      shields: [
        { key: 1, value: "Stronghold" },
        { key: 2, value: "Stronghold" },
      ],
      quantumdrive: "Kama"
    },
    weaponry: {
      turrets: [
        {
          key: 1,
          name: "Manned 4xS4",
          hardpoints: [
            {
              key: 1,
              name: "Turret",
              type: "Turret",
              size: 4,
              weapon: "CF-447 Rhino"
            },
            {
              key: 2,
              name: "Turret",
              type: "Turret",
              size: 4,
              weapon: "CF-447 Rhino"
            },
            {
              key: 3,
              name: "Turret",
              type: "Turret",
              size: 4,
              weapon: "CF-447 Rhino"
            },
            {
              key: 4,
              name: "Turret",
              type: "Turret",
              size: 4,
              weapon: "CF-447 Rhino"
            }
          ]
        },
        {
          key: 2,
          name: "Manned 4xS4",
          hardpoints: [
            {
              key: 1,
              name: "Turret",
              type: "Turret",
              size: 4,
              weapon: "CF-447 Rhino"
            },
            {
              key: 2,
              name: "Turret",
              type: "Turret",
              size: 4,
              weapon: "CF-447 Rhino"
            },
            {
              key: 3,
              name: "Turret",
              type: "Turret",
              size: 4,
              weapon: "CF-447 Rhino"
            },
            {
              key: 4,
              name: "Turret",
              type: "Turret",
              size: 4,
              weapon: "CF-447 Rhino"
            }
          ]
        },
        {
          key: 3,
          name: "Manned 4xS4",
          hardpoints: [
            {
              key: 1,
              name: "Turret",
              type: "Turret",
              size: 4,
              weapon: "CF-447 Rhino"
            },
            {
              key: 2,
              name: "Turret",
              type: "Turret",
              size: 4,
              weapon: "CF-447 Rhino"
            },
            {
              key: 3,
              name: "Turret",
              type: "Turret",
              size: 4,
              weapon: "CF-447 Rhino"
            },
            {
              key: 4,
              name: "Turret",
              type: "Turret",
              size: 4,
              weapon: "CF-447 Rhino"
            }
          ]
        },
        {
          key: 4,
          name: "Manned 4xS4",
          hardpoints: [
            {
              key: 1,
              name: "Turret",
              type: "Turret",
              size: 4,
              weapon: "CF-447 Rhino"
            },
            {
              key: 2,
              name: "Turret",
              type: "Turret",
              size: 4,
              weapon: "CF-447 Rhino"
            },
            {
              key: 3,
              name: "Turret",
              type: "Turret",
              size: 4,
              weapon: "CF-447 Rhino"
            },
            {
              key: 4,
              name: "Turret",
              type: "Turret",
              size: 4,
              weapon: "CF-447 Rhino"
            }
          ]
        },
        {
          key: 5,
          name: "Manned 4xS4",
          hardpoints: [
            {
              key: 1,
              name: "Turret",
              type: "Turret",
              size: 4,
              weapon: "CF-447 Rhino"
            },
            {
              key: 2,
              name: "Turret",
              type: "Turret",
              size: 4,
              weapon: "CF-447 Rhino"
            },
            {
              key: 3,
              name: "Turret",
              type: "Turret",
              size: 4,
              weapon: "CF-447 Rhino"
            },
            {
              key: 4,
              name: "Turret",
              type: "Turret",
              size: 4,
              weapon: "CF-447 Rhino"
            }
          ]
        },
        {
          key: 6,
          name: "Manned 4xS4",
          hardpoints: [
            {
              key: 1,
              name: "Turret",
              type: "Turret",
              size: 4,
              weapon: "CF-447 Rhino"
            },
            {
              key: 2,
              name: "Turret",
              type: "Turret",
              size: 4,
              weapon: "CF-447 Rhino"
            },
            {
              key: 3,
              name: "Turret",
              type: "Turret",
              size: 4,
              weapon: "CF-447 Rhino"
            },
            {
              key: 4,
              name: "Turret",
              type: "Turret",
              size: 4,
              weapon: "CF-447 Rhino"
            }
          ]
        },
      ],
      hardpoints: []
    },
    missiles: [
      {
        key: 1,
        rack: "MSD-543",
        missiles: [
          { key: 1, value: "Arrester III" },
          { key: 2, value: "Arrester III" },
          { key: 3, value: "Arrester III" },
          { key: 4, value: "Arrester III" }
        ]
      },
      {
        key: 2,
        rack: "MSD-543",
        missiles: [
          { key: 1, value: "Arrester III" },
          { key: 2, value: "Arrester III" },
          { key: 3, value: "Arrester III" },
          { key: 4, value: "Arrester III" }
        ]
      },
      {
        key: 3,
        rack: "MSD-543",
        missiles: [
          { key: 1, value: "Arrester III" },
          { key: 2, value: "Arrester III" },
          { key: 3, value: "Arrester III" },
          { key: 4, value: "Arrester III" }
        ]
      },
      {
        key: 4,
        rack: "MSD-543",
        missiles: [
          { key: 1, value: "Arrester III" },
          { key: 2, value: "Arrester III" },
          { key: 3, value: "Arrester III" },
          { key: 4, value: "Arrester III" }
        ]
      },
      {
        key: 5,
        rack: "MSD-543",
        missiles: [
          { key: 1, value: "Viper III" },
          { key: 2, value: "Viper III" },
          { key: 3, value: "Viper III" },
          { key: 4, value: "Viper III" }
        ]
      },
      {
        key: 6,
        rack: "MSD-543",
        missiles: [
          { key: 1, value: "Viper III" },
          { key: 2, value: "Viper III" },
          { key: 3, value: "Viper III" },
          { key: 4, value: "Viper III" }
        ]
      },
      {
        key: 7,
        rack: "MSD-543",
        missiles: [
          { key: 1, value: "Viper III" },
          { key: 2, value: "Viper III" },
          { key: 3, value: "Viper III" },
          { key: 4, value: "Viper III" }
        ]
      },
      {
        key: 8,
        rack: "MSD-543",
        missiles: [
          { key: 1, value: "Viper III" },
          { key: 2, value: "Viper III" },
          { key: 3, value: "Viper III" },
          { key: 4, value: "Viper III" }
        ]
      },
    ],
    alternatives: []
  },
];

export default aegis;