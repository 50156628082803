import { Loadout } from "../../types/types";
import { EclipseDuo, FR76Duo, SnowpackDuo, TarantulaTurret, XL1,
MSD322Dominators, 
JS500Single,
ChillMaxDuo,
CombineTurret,
CombineNoseGimbal,
FR86Duo} from "../presets";

const crus:Loadout[] = [
  {
    name: "Mercury Star Runner",
    notes:[],
    avionics: {
      powerplants: EclipseDuo,
      coolers: SnowpackDuo,
      shields: FR76Duo,
      quantumdrive: XL1,
    },
    weaponry: {
      turrets: [
        {
          key: 1,
          name: "Manned 2xS3",
          hardpoints: [
            TarantulaTurret(1), TarantulaTurret(2)
          ]
        },
        {
          key: 2,
          name: "Manned 2xS3",
          hardpoints: [
            TarantulaTurret(1), TarantulaTurret(2)
          ]
        },
      ],
      hardpoints: [
        TarantulaTurret(1), TarantulaTurret(2)
      ]
    },
    missiles: [
      MSD322Dominators(1), MSD322Dominators(2), MSD322Dominators(3), MSD322Dominators(4)
    ],
    alternatives: []
  },
  {
    name: "Hercules Starlifter C2",
    notes:[],
    avionics: {
      powerplants: JS500Single,
      coolers: ChillMaxDuo,
      shields: FR86Duo,
      quantumdrive: "Balandin",
    },
    weaponry: {
      turrets: [
        {
          key: 1,
          name: "Remote 2xS4",
          hardpoints: [
            CombineTurret(1),
            CombineTurret(2)
          ]
        }
      ],
      hardpoints: [
        CombineNoseGimbal(1),
        CombineNoseGimbal(2),
      ]
    },
    missiles: [],
    alternatives: []
  },
  {
    name: "Hercules Starlifter M2",
    notes:[],
    avionics: {
      powerplants: JS500Single,
      coolers: ChillMaxDuo,
      shields: FR86Duo,
      quantumdrive: "Balandin",
    },
    weaponry: {
      turrets: [
        {
          key: 1,
          name: "Remote 2xS4",
          hardpoints: [
            CombineTurret(1),
            CombineTurret(2),
          ]
        },
        {
          key: 2,
          name: "Remote 2xS4",
          hardpoints: [
            CombineTurret(1),
            CombineTurret(2),
          ]
        },
        {
          key: 3,
          name: "Remote 2xS4",
          hardpoints: [
            CombineTurret(1),
            CombineTurret(2),
          ]
        }
      ],
      hardpoints: [
        CombineNoseGimbal(1),
        CombineNoseGimbal(2),
      ]
    },
    missiles: [],
    alternatives: []
  },
]

export default crus;