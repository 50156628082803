import stock from "./builds/stock";
import { Avionics, Loadout, Missiles, ShoppingItem, Weaponry } from "./types/types";

class ShoppingListGenerator {
  Generate(loadout: Loadout) {
    let defaultBuild = stock.find(s => s.name === loadout.name);
    if (defaultBuild === undefined) return;
    let avionicsDiff = this.GetAvionicsList(defaultBuild.avionics, loadout.avionics)
    let weaponryDiff = this.GetWeaponryList(defaultBuild.weaponry, loadout.weaponry);
    let missileDiff = this.GetMissileList(defaultBuild.missiles, loadout.missiles);

    //console.log(avionicsDiff);
    //console.log(weaponryDiff);
    //console.log(missileDiff)
    return [...avionicsDiff, ...weaponryDiff, ...missileDiff]
  }

  GetAvionicsList(stock: Avionics, custom: Avionics): ShoppingItem[] {
    let difference: ShoppingItem[] = []

    // Power plants
    for (let powerPlant of stock.powerplants) {
      let customPower = custom.powerplants.find(p => p.key === powerPlant.key);
      if (customPower === undefined) continue;
      if (customPower.value !== powerPlant.value) {
        difference = this.AddToList(difference, { name: customPower.value, quantity: 1, type: 'power' })
      }
    }

    // Shields
    for (let shield of stock.shields) {
      let customShield = custom.shields.find(s => s.key === shield.key);
      if (customShield === undefined) continue;
      if (customShield.value !== shield.value) {
        difference = this.AddToList(difference, { name: customShield.value, quantity: 1, type: 'shield' })
      }
    }

    // Coolers
    for (let cooler of stock.coolers) {
      let customCooler = custom.coolers.find(s => s.key === cooler.key);
      if (customCooler === undefined) continue;
      if (customCooler.value !== cooler.value) {
        difference = this.AddToList(difference, { name: customCooler.value, quantity: 1, type: 'cooler' })
      }
    }

    // Quantum drive
    if (custom.quantumdrive !== stock.quantumdrive) {
      difference = this.AddToList(difference, { name: custom.quantumdrive, quantity: 1, type: 'quantum' })
    }

    return difference
  }

  GetWeaponryList(stock: Weaponry, custom: Weaponry): ShoppingItem[] {
    let difference: ShoppingItem[] = [];

    // Turrets
    for (let turret of stock.turrets) {
      let customTurret = custom.turrets.find(t => t.key === turret.key);
      if (customTurret === undefined) continue;

      if (customTurret.name !== turret.name) {
        difference = this.AddToList(difference, { name: customTurret.name, quantity: 1, type: 'turret' })
      }

      for (let hardpoint of customTurret.hardpoints) {
        let stockHardpoint = turret.hardpoints.find(h => h.key === hardpoint.key);
        // Cover adding
        if (stockHardpoint === undefined) {
          difference = this.AddToList(difference, { name: hardpoint.weapon, quantity: 1, type: 'weapon' });
          continue;
        }

        // Add gimbal mount
        if (stockHardpoint.type === 'Fixed' && hardpoint.type === 'Gimbal') {
          difference = this.AddToList(difference, { name: `VariPuck S${hardpoint.size} Gimbal Mount`, quantity: 1, type: 'gimbal' })
        }

        // Add gun
        if (stockHardpoint.weapon !== hardpoint.weapon) {
          difference = this.AddToList(difference, { name: hardpoint.weapon, quantity: 1, type: 'weapon' })
        }
      }
    }

    // Guns
    for (let hardpoint of custom.hardpoints) {
      let stockHardpoint = stock.hardpoints.find(h => h.key === hardpoint.key);
      // Cover adding
      if (stockHardpoint === undefined) {
        difference = this.AddToList(difference, { name: hardpoint.weapon, quantity: 1, type: 'weapon' });
        continue;
      }

      // Add gimbal mount
      if (stockHardpoint.type === 'Fixed' && hardpoint.type === 'Gimbal') {
        difference = this.AddToList(difference, { name: `VariPuck S${hardpoint.size} Gimbal Mount`, quantity: 1, type: 'gimbal' })
      }

      // Add gun
      if (stockHardpoint.weapon !== hardpoint.weapon) {
        difference = this.AddToList(difference, { name: hardpoint.weapon, quantity: 1, type: 'weapon' })
      }
    }

    return difference;
  }

  GetMissileList(stock: Missiles[], custom: Missiles[]): ShoppingItem[] {
    let difference: ShoppingItem[] = [];

    for (let rack of stock) {
      let customRack = custom.find(r => r.key === rack.key);
      if (customRack === undefined) continue;

      if (customRack.rack !== rack.rack) {
        //console.log('added rack')
        difference = this.AddToList(difference, { name: customRack.rack, quantity: 1, type: 'rack' })
      }

      for (let missile of customRack.missiles) {
        //console.log(missile);
        let stockMissile = rack.missiles.find(m => m.key === missile.key);
        // Cover adding
        if (stockMissile === undefined) {
          //console.log('added missile')
          difference = this.AddToList(difference, { name: missile.value, quantity: 1, type: 'missile' });
          continue;
        }

        if (stockMissile.value !== missile.value){
          //console.log('added missile')
          difference = this.AddToList(difference, { name: missile.value, quantity: 1, type: 'missile' });
        }
      }
    }

    return difference;
  }

  AddToList(list: ShoppingItem[], toAdd: ShoppingItem) {
    let existing = list.find(i => i.name === toAdd.name);
    if (existing !== undefined) {
      list[list.indexOf(existing)].quantity++;
    } else {
      list.push(toAdd)
    }
    return list;
  }
}

export default new ShoppingListGenerator();